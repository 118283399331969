import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Bhafpx4102assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>BHA FPX 4102 Assessment 3 Cultural Competence</title>
                <meta name='description'
                    content='Ace your BHA FPX 4102 Assessment 3 Cultural Competence! Get expert guidance on cultural competence. Enroll today.' />
                <meta name='keywords' content='BHA FPX 4102 Assessment 3 Cultural Competence' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>BHA FPX 4102 Assessment 3 < br /><span>Cultural Competence</span></h1>

                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/BHAFPX4102Assessment3.png" alt="" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>BHA FPX 4102 Assessment 3 Cultural Competence.</strong></em></li>
                        </ul>
                        <h2>Cultural Competence</h2>
                        <p>Cultural competence and diversity skills have become fundamental requirements of the world today, particularly in fields like healthcare, where people from different cultural backgrounds constantly interact. Being culturally competent in health care ensures that care is customized for the unique beliefs and practices of patients in particular cultures (Majda et al., 2021).</p>
                        <p>Cultural intelligence or cultural quotient (CQ) refers to the ability to function effectively in culturally diverse settings. In healthcare professions, high CQ professionals are better placed to cross-cultural gaps, provide appropriate care, and gain patients' trust if they come from different backgrounds (Luquis, 2021).</p>
                        <p>This helps healthcare leaders develop CQ critically to encourage cultural competency within their organizations so that every member is respected and understood. Cultural competence, leadership, teamwork, and CQ are also intertwined aspects vital to producing success in multicultural workplaces, especially in healthcare workplaces where it may directly impact patient satisfaction.</p>
                        <h2>Part 1: Cultural Competence Self-Reflection</h2>
                        <ul>
                            <li>
                                <h3><strong>Results for the Assessment of Cultural Diversity</strong></h3>
                            </li>
                        </ul>
                        <p>In the Leadership Culture, Diversity, and Outgroup Cultural Diversity Assessment, my score is 85 on a scale of 100, meaning I am at a high level of cultural intelligence. This rank falls within the range of "cultural intelligence"; hence, one can operate effectively in culturally diverse environments. I was not shocked by that result because I intended to learn about the various cultures and how they impact interactions and decision-making.</p>
                        <p>The score is due to my experience working with diverse teams and communicating across cultures. My interest in cultural diversity and commitment to increasing my knowledge on the subject have consequently developed my cultural intelligence over time.</p>
                        <p>This further reassures my commitment to bringing inclusiveness and learning within leadership. It shows that I am capable of leading diverse teams consisting of people with different cultural orientations. Moving forward, I will continue to use cultural competence to emphasize my approach to leadership and find ways of broadening the scope of my understanding of how other cultures shape the organization (Egitim, 2022). My ultimate goal is to set up places where people value all individuals and empower them to contribute their viewpoints and experiences.</p>
                        <ul>
                            <li>
                                <h3>Areas for Improvement</h3>
                            </li>
                        </ul>
                        <p>Along with cultural diversity, I hope to learn more about different cultural practices and become aware of biases. In this regard, I will begin by expanding my knowledge of other cultures' customs, traditions, and communication tools that I do not know much about. I will try to find as many opportunities as possible to learn from other people with various backgrounds and join some kind of cultural sensitivity training or workshops. Expanding my cultural understanding should better equip me to handle different relationships and facilitate more efficient environments.</p>
                        <p>I also recognize that unconscious biases can influence my perception and behavior toward people of other cultures. I shall tend to regularly return to reflection on how to discover and overcome any unnoticed biases within me. I will personally work on incorporating diversity and inclusiveness into personal and professional settings. I will also advocate for fair practices and policies that exclude no one based on culture and that form combative steps to my biases in creating spaces that will welcome all cultures and promote respectability.</p>
                        <ul>
                            <li>
                                <h3>Potential Benefits and Plans for Progress</h3>
                            </li>
                        </ul>
                        <p>Understanding more specific cultural practices and raising my awareness of unconscious bias will make me a better fit for my career (Ogunyemi, 2021). It helps me get along better with colleagues from diverse backgrounds and clients with whom I communicate, collaborate, and address problems with greater cultural competency.</p>
                        <p>It thereby made me a stronger leader and team player in my chosen field. By being aware of unconscious biases, I will reduce their influence over my decision-making processes and interpersonal relationships, ensuring fairness, equity, and inclusiveness in my working life.</p>
                        <h3>BHA FPX 4102 Assessment 3 Cultural Competence</h3>
                        <p>I have certain strategies for making progress in such areas. First, I will seek opportunities to increase my knowledge regarding cultural practices through different resources such as books, articles, and cultural competency training programs. Further, I will look for the opportunity to interact with people from diverse backgrounds and hold meaningful discussions with them.</p>
                        <p>These will give me firsthand information about their experiences and enable me to understand different cultures better. I will self-reflect further by observing my thoughts and attitudes toward various cultural groups. Self-reflection helps to improve decision-making power (Beard et al., 2021). This will help me discover any unconscious biases, which I will address. I am also looking forward to constructive feedback from my peers and mentors on any areas for growth that need attention.</p>
                        <p>Their feedback will guide me in identifying what I should develop to enhance my effectiveness in this competency. I will continually apply the recommended strategies, and I am sure to make great strides in building my cultural diversity skills and advancing my career.</p>
                        <h2>Part 2: Analysis of Culture, Leadership, and Teamwork</h2>
                        <ul>
                            <li>
                                <h3>Cultural Competence and Teamwork/Collaboration</h3>
                            </li>
                        </ul>
                        <p>Cultural competency creates an environment for teamwork and collaboration through understanding, respecting, and valuing other people's diverse perspectives and experiences. When cultural competency values are in place, people generally become better at relating, communicating, and working with others from different backgrounds (Markey et al., 2021).</p>
                        <p>This creates more trust and cohesion among team members because every single member will feel respected and recognized, thus not caring about cultural identity. Moreover, teams that exhibit cultural competence can more effectively handle any potential conflicts or miscommunication related to cultural differences, ensuring easy interaction and successful teamwork.</p>
                        <p>Cultural competence fosters creativity and innovation in teams since it encourages the participation of different ideas and thoughts. If diverse thoughts about problem-solving are shared among various employees, this brings a larger scope of solutions (Barak &amp; Yuan, 2021).</p>
                        <p>This diversity can be effective since teams having more perspectives toward complexities can respond very well. Cultural competence also leads to an effective team climate with inclusiveness, openness, and respect for others. It encourages individual members to collaborate and put their utmost effort into achieving the desired team goals when they are made to feel welcome and accepted regardless of their cultural backgrounds.</p>
                        <p>Cultural competence, teamwork, and cooperation mutually reinforce each other. Cultural competence is directly linked with enhanced communication, understanding, and respect building within the team to gain trust and sharing between members (Hopf et al., 2021).</p>
                        <p>Cultural competence also fosters creativity, innovation, and problem-solving abilities in individuals who are exposed to diverse perspectives and approaches. Teams that work to attain cultural competence create environments where each individual feels valued, included, and empowered. They build a culture where each member is encouraged to contribute their diverse talent and input to foster success.</p>
                        <ul>
                            <li>
                                <h3>Distinguish Between Cultural Awareness, Knowledge, Skills, and Sensitivity</h3>
                            </li>
                        </ul>
                        <p>Cultural awareness forms the basis for recognizing and appreciating diversity. Cultural awareness represents a certain level of consciousness by a person regarding the existence of different cultural groups with specific practices, beliefs, and values unique to each group (Aririguzoh, 2022).</p>
                        <p>However, cultural awareness also includes recognizing one's cultural background and how it influences one's point of view. Cultural knowledge refers to the understanding and familiarity with particular cultural traditions, customs, and norms of various groups (Aririguzoh, 2022).</p>
                        <h3>BHA FPX 4102 Assessment 3 Cultural Competence</h3>
                        <p>Cultural competence starts with obtaining knowledge about different cultures through education, research, and interaction with people from different backgrounds. It is a practice that requires more knowledge or awareness; rather, it requires appropriately interacting and relating to individuals from various cultural backgrounds and appropriately communicating.</p>
                        <p>It involves not being disrespectful of cultural differences and changing one's behavior and communication style to include many cultural norms (Aririguzoh, 2022). However, cultural sensitivity refers to an awareness of people's feelings, needs, and views from other cultures. It requires consideration of others' feelings and refraining from making assumptions or stereotypes, thereby respecting the differences between various beliefs and their practices.</p>
                        <p>Leaders in health care need to understand these differences, given the expectation of providing high-quality patient-centered care in a culturally diverse setting. The knowledge of culture facilitates understanding of how their beliefs, behaviors, and preferences for care correlate with patients' health (Engle et al., 2021).</p>
                        <p>Such information provides the essentials to deliver culturally sensitive care while making decisions respecting a patient's background. Cultural competence creates the ability of leaders to communicate effectively with patients who come from different cultures. This leads to trust and relationship building in therapy, producing positive health outcomes. Lastly, cultural sensitivity ensures that healthcare leaders respect, appreciate, and understand each patient's cultural identity and experiences. This leads to an inclusive and equitable healthcare system.</p>
                        <ul>
                            <li>
                                <h3>Cultural Differences in Communication Styles</h3>
                            </li>
                        </ul>
                        <p>Cultural differences can easily lead to misunderstandings in verbal and nonverbal communication. In verbal communication, language issues may arise when people speak different languages, dialects, or accents. This may cause misinterpretation and even a communication breakdown if one of the parties does not have an ample understanding of the language being used (Ellahham, 2021).</p>
                        <p>Furthermore, words and phrases may carry different meanings or connotations in different cultures. Without such cultural differences, an innocent expression in one culture can be seen as offensive in another. Verbal and non-verbal expressions include body language, facial expressions, and gestures (Zijp, 2024).</p>
                        <p>For example, while some cultures use eye contact to indicate attention and respect, it can be seen as aggressive or rude in others. Similarly, some ordinary actions, like handshakes, nods, or bows, may have different meanings to different individuals in the culture, and if not put into perspective, misunderstanding can happen.</p>
                        <p>Personal space and cultural considerations about physical contact also affect nonverbal communication. One culture may readily accept and encourage certain behaviors, while another may view the same actions as inappropriate or intrusive. The mode of communication of both parties might also cause miscommunication. Some societies prefer open communication, where people directly utter their thoughts.</p>
                        <p>Indirect communication cultures rely instead on subtle, implied messages that often end with using context and nonverbal cues (Patterson et al., 2023). Without identification of these different communication styles, they may lead to confusion or misinterpretation of intent. This can be especially true in cross-cultural settings, where individuals may not know each other's norms and customs.</p>
                        <ul>
                            <li>
                                <h3>Impact of the Misunderstandings and Solution</h3>
                            </li>
                        </ul>
                        <p>The implications of verbal and nonverbal miscommunication in a healthcare setting can have serious consequences. Poor communication among health professionals and their clients about essential information may result in failures in inappropriate diagnosis or management. Such situations mainly cause dissatisfaction on the side of patients and a lack of confidence in the healthcare provider, resulting in avoidance of future visitations for medical care (Dahm et al., 2021).</p>
                        <p>In extreme cases, such miscommunications can lead to medical malpractice lawsuits or poor patient health outcomes. Communication failure also leads to tension or conflict within healthcare teams, thereby negatively impacting teamwork and collaboration.</p>
                        <p>To address these issues and prevent related misunderstandings within healthcare cross-cultural settings, healthcare administrators have several different approaches they can take. The first is to provide cultural competency training for healthcare personnel to make them more aware and sensitive to the differences in communication style, norms, and values across cultures (Majda et al., 2021).</p>
                        <h3>BHA FPX 4102 Assessment 3 Cultural Competence</h3>
                        <p>This will empower providers with the best skills to deal with cross-cultural interactions and to communicate more effectively with patients who belong to different backgrounds. Another choice is on-site professional interpreters or language assistance services where patients who are not proficient in English or speak other languages will communicate effectively (Mirza et al., 2020).</p>
                        <p>Open communication among the team and encouraging feedback from healthcare leaders will help create an atmosphere where misunderstandings or conflict due to cultural differences become expected and even constructively engaged (Mirza et al., 2020). With respect, empathy, and inclusiveness, healthcare leaders can create settings where all patients will feel understood, valued, and respected- culturally, by promoting respect, empathy, and inclusivity.</p>
                        <h2>Conclusion</h2>
                        <p>Healthcare leaders must have cultural competence, continuous education, and a caring culture to ensure effective management of cross-cultural relationships. Miscommunications, verbally and non-verbally, lead to wrong diagnoses, dissatisfied patients, and internal team conflicts may ensue.</p>
                        <p>Effective leadership needs to stress professional competency training in matters of culture, provide professional interpreter services when the need arises, and foster open communication and respect among the healthcare team. With a culture of sensitivity and inclusiveness, leaders can promote better-quality, patient-centered care for all people, which covers different patients' needs to supplement their experience of health care.</p>
                        <h2>References</h2>
                        <p>Aririguzoh, S. (2022). Communication competencies, culture, and SDGs: Effective processes to cross-cultural communication. <em>Humanities and Social Sciences Communications</em>, <em>9</em>(1), 1&ndash;11.</p>
                        <p><a href="https://doi.org/10.1057/s41599-022-01109-4">https://doi.org/10.1057/s41599-022-01109-4</a></p>
                        <p>Barak, M., &amp; Yuan, S. (2021). A cultural perspective to project-based learning and the cultivation of innovative thinking. <em>Thinking Skills and Creativity</em>, <em>39</em>, 100766.</p>
                        <p><a href="https://doi.org/10.1016/j.tsc.2020.100766">https://doi.org/10.1016/j.tsc.2020.100766</a></p>
                        <p>Beard, S., Shapiro, M., &amp; Ingols, C. (2021). A model for strengthening mentors: Frames and practices. <em>International Journal of Environmental Research and Public Health</em>, <em>18</em>(12), 6465.</p>
                        <p><a href="https://doi.org/10.3390/ijerph18126465">https://doi.org/10.3390/ijerph18126465</a></p>
                        <p>Dahm, M. R., Williams, M., &amp; Crock, C. (2021). &ldquo;More than words&rdquo; - Interpersonal communication, cognitive bias, and diagnostic errors. <em>Patient Education and Counseling</em>, <em>105</em>(1), 252&ndash;256.</p>
                        <p><a href="https://doi.org/10.1016/j.pec.2021.05.012">https://doi.org/10.1016/j.pec.2021.05.012</a></p>
                        <p>Egitim, S. (2022). Challenges of adapting to organizational culture: Internationalization through inclusive leadership and mutuality. <em>Social Sciences &amp; Humanities Open</em>, <em>5</em>(1), 100242.</p>
                        <p><a href="https://doi.org/10.1016/j.ssaho.2021.100242">https://doi.org/10.1016/j.ssaho.2021.100242</a></p>
                        <p>Ellahham, S. (2021). Communication in health care. <em>American Journal of Medical Quality</em>, <em>36</em>(5), 355&ndash;364.</p>
                        <p><a href="https://doi.org/10.1097/01.jmq.0000735476.37189.90">https://doi.org/10.1097/01.jmq.0000735476.37189.90</a></p>
                        <p>Engle, R. L., Mohr, D. C., Holmes, S. K., Seibert, M. N., Afable, M., Leyson, J., &amp; Meterko, M. (2021). Evidence-based Practice and Patient-centered Care: Doing Both Well. <em>Health Care Management Review</em>, <em>46</em>(3), 174&ndash;184.</p>
                        <p><a href="https://doi.org/10.1097/HMR.0000000000000254">https://doi.org/10.1097/HMR.0000000000000254</a></p>
                        <p>Hopf, S. C., Crowe, K., Verdon, S., Blake, H. L., &amp; McLeod, S. (2021). Advancing workplace diversity through the culturally responsive teamwork framework. <em>American Journal of Speech-Language Pathology</em>, <em>30</em>(9), 1&ndash;13.</p>
                        <p><a href="https://doi.org/10.1044/2021_ajslp-20-00380">https://doi.org/10.1044/2021_ajslp-20-00380</a></p>
                        <p>Luquis, R. R. (2021). Integrating the concept of cultural intelligence into health education and health promotion. <em>Health Education Journal</em>, <em>80</em>(7), 833&ndash;843.</p>
                        <p><a href="https://doi.org/10.1177/00178969211021884">https://doi.org/10.1177/00178969211021884</a></p>
                        <p>Majda, A., Cupak, I. E., Puchała, J., &amp; Barzykowski, K. (2021). Cultural competence and cultural intelligence of healthcare professionals providing emergency medical services. <em>International Journal of Environmental Research and Public Health</em>, <em>18</em>(21), 11547.</p>
                        <p><a href="https://doi.org/10.3390/ijerph182111547">https://doi.org/10.3390/ijerph182111547</a></p>
                        <p>Markey, K., Prosen, M., Martin, E., &amp; Repo Jamal, H. (2021). Fostering an ethos of cultural humility development in nurturing inclusiveness and effective intercultural teamwork. <em>Journal of Nursing Management</em>, <em>29</em>(8), 2724&ndash;2728.</p>
                        <p><a href="https://doi.org/10.1111/jonm.13429">https://doi.org/10.1111/jonm.13429</a></p>
                        <p>Mirza, M., Harrison, E. A., Roman, M., Miller, K. A., &amp; Jacobs, E. A. (2020). Walking the talk: Understanding how language barriers affect the delivery of rehabilitation services. <em>Disability and Rehabilitation</em>, <em>44</em>(2), 1&ndash;14.</p>
                        <p><a href="https://doi.org/10.1080/09638288.2020.1767219">https://doi.org/10.1080/09638288.2020.1767219</a></p>
                        <p>Ogunyemi, D. (2021). Defeating unconscious bias: The role of a structured, reflective, and interactive workshop. <em>Journal of Graduate Medical Education</em>, <em>13</em>(2), 189&ndash;194.</p>
                        <p><a href="https://doi.org/10.4300/jgme-d-20-00722.1">https://doi.org/10.4300/jgme-d-20-00722.1</a></p>
                        <p>Patterson, M. L., Fridlund, A. J., &amp; Crivelli, C. (2023). Four misconceptions about nonverbal communication. <em>Perspectives on Psychological Science</em>, <em>18</em>(6), 1388&ndash;1411.</p>
                        <p><a href="https://doi.org/10.1177/17456916221148142">https://doi.org/10.1177/17456916221148142</a></p>
                        <p>Zijp, D. (2024). Comic innocence. <em>The European Journal of Humour Research</em>, <em>12</em>(1), 116&ndash;134.</p>
                        <p><a href="https://www.ceeol.com/search/article-detail?id=1241948">https://www.ceeol.com/search/article-detail?id=1241948</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Bhafpx4102assessment3
