import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Nursfpx5005assessment4 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>NURS FPX 5005 Assessment 4 Analysis of Technology in Nursing Practice</title>
                <meta name='description'
                    content="Ace your NURS FPX 5005 Assessment 4 Analysis of Technology in Nursing Practice at Online course services. Discover the benefits, challenges, and ethical considerations with our expert's help. Contact us!" />
                <meta name='keywords' content='NURS FPX 5005 Assessment 4 Analysis of Technology in Nursing Practice' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>NURS FPX 5005 Assessment 4 < br /><span>Analysis of Technology in Nursing Practice</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/NURSFPX5005Assessment4.png" alt="NURS FPX 5005 Assessment 4" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>NURS FPX 5005 Assessment 4 Analysis of Technology in Nursing Practice</strong></em></li>
                        </ul>
                        <h2>Analysis of Technology in Nursing Practice</h2>
                        <p>By analyzing how technology impacts nurse practice, there is the likelihood of improving patient care due to enhanced speed, accuracy, and accessibility of medical information. Information technologies that may include telemedicine, remote monitoring tools, and electronic health information facilitate the nurses' decision-making and communication and enhance the Health of the patients served by the nurses (Chen et al., 2020). Understanding and applying these instruments in the context of nursing practice will be crucial to developing a healthcare system that is malleable and changes to the patient's and HCW's needs as they evolve.</p>
                        <h2>Impact of Telehealth on Patients</h2>
                        <p>The impact of expertise in patient care is significant since telehealth technology is an aspect that brings easier access to medical care to those who are confined to their homes or find it difficult to move around. It also induces immediate action, and a great number of individuals can obtain therapy without much effort. For instance, Telehealth allows individuals in rural areas to receive standard appointments for monitoring chronic illnesses since physicians can, from a distance, assess symptoms and patient's adherence to prescribed medication regimens; patients experience improved outcomes as a result. However, the solid Internet link is challenging to install, and it is tricky to make sure that all patients will be content with such a device (Keenan et al., 2020). Nevertheless, there are also some positives in Telehealth for nursing, such as increasing patient engagement and access to care (Phillips et al., 2020).</p>
                        <p>Telehealth is very impactful in patient care because the general availability is increased, it is cost-effective, it improves the well-being of patients, and it has positive patient progression. Also, the American Medical Association argues that total travel expenditures of $94,327 save the industry an average of $1,563,281 per 1000 patients (Shimbo et al., 2020). Still, according to the Mayo Clinic, their patient satisfaction rate stands at 95%, meaning that patients are very satisfied with their care. Clinical outcomes are improving, especially for those chronic diseases.</p>
                        <h3>NURS FPX 5005 Assessment 4 Analysis of Technology in Nursing Practice</h3>
                        <p>For instance, patients with congestive heart failure loop diuretic use significantly reduced their risk of readmission by 20%, according to Blecker and his fellow researchers. Since the service can be applied to a varying population, the factors must be monitored according to the cause of the ailment. Therefore, technology enables doctors to monitor the progress of patients from a distance, conduct proven therapies, and switch therapies swiftly. All of these things are useful in supporting doctor decision-making processes (Omboni et al., 2022).&nbsp;</p>
                        <p>Telehealth greatly impacts nursing practice as it alters how individuals access and utilize their healthcare. It adds to a nurse's job by allowing them to monitor, educate, and assess patients without physical contact, bypassing geographical barriers and making care more about the patient (Omboni et al., 2022). Telehealth also helps facilitate communication among healthcare teams, enhancing broad teamwork and case sharing. Consequentially, it leads to improved nurse services, which are easier to access.</p>
                        <h2>Communication of Data</h2>
                        <p>Telehealth-generated data follow several paths to inform physicians' decisions and improve patient management. Initially, the collected data includes the patient's history, clinical history, measurement and recording, and lab test results. Digital communication uses secured online tools to share data among medical workers. Some of this information goes a long way in helping doctors make factual decisions when they are setting up treatment and actions. For instance, doctors rely on this information to ensure patients with chronic diseases such as diabetes or heart failure receive adequate doses of medicine.</p>
                        <p>This complete information is also provided to the team, and the team is composed of the nurses, pharmacists, and many other specialists who are working for the patient and are not restricted to a certain specialty only. Communication may occur during the regularly scheduled face-to-face conversation or through messages where integrated information exchange enables all participants to receive the overall view of the patient's state and the plan of further action (Springer et al., 2020). This simple sharing of information between medical staff members assists in the organization of care and also makes it easy to respond to patients' needs, hence providing complete and patient-centered care.</p>
                        <p>In the CRAAP test, five criteria are used to judge the telehealth data: currency, relevance, authority, accuracy, and purpose. The first criterion is fulfilled because the sources are current, and there has been evident growth in Telehealth during COVID-19 (Esparrago-Kalidas, 2021). There is no doubt that the data is relevant because it is about patient care and how patients' Health is impacted.</p>
                        <p>We are assured of correctness by renowned groups such as the American Medical Association and the Mayo Clinic, meaning the information given can be relied on. It stems from the reliable groups and organizations that did the study, which makes the material reliable (Dilick &amp; Jones, 2022). Finally, all the purpose of the standards is achieved because, with the data, healthcare workers get to make informed decisions in the care they offer patients.</p>
                        <h2>Patient Safety and Confidentiality</h2>
                        <p>The data is secure, and safe methods of contacting ensure that the patient's information is secure and confidential. Policies such as the Health Insurance Portability and Accountability Act help ensure that patient data is secure and can only be accessed by those who are allowed to view it (Chinmoy et al., 2020). At any given level, data is worked on, messages are delivered, and finally, patient data privacy and consent are honored.</p>
                        <p>Since imperfections are unavoidable, clinical and administrative controls must be well thought out. These are things such as risk analysis and reducing the probability of risks occurring. Every team member knows how to guard patients' data because they have been taught appropriately on measures to be used to protect data. Users and passwords are required to prevent data leakage and prevent people from accessing something they shouldn't. A form of health check and examination is constantly conducted on data to prevent loss and ensure that, in case of incidence, it can be easily detected and rectified promptly (Faget et al., 2021).</p>
                        <h2>Evidence-Based Strategies</h2>
                        <p>For instance, to be most effective, a telehealth patient care tool has to be applied as has been researched and proven. RCT is one way to compare whether Telehealth is more or less effective within diverse contexts or for different patient populations (Gordon et al., 2021). Therefore, the following strategies have been found to work in several areas when applying Telehealth. According to Souza et al. (2021), the practices are higher when clear clinical questions are formulated by using models such as PICO, a lot of relevant and updated evidence gets searched, and the data is well critiqued.</p>
                        <p>To use Telehealth, you must know about results, how to switch to new information and follow all legalities and moralities. The study's authors, White et al. (2022), recommend that integrated care teams, patients, and providers communicate more clearly and effectively.</p>
                        <h3>NURS FPX 5005 Assessment 4 Analysis of Technology in Nursing Practice</h3>
                        <p>Through the optimal patient outcomes in many healthcare processes, these data-based solutions guarantee that video treatments meet the best practices (Bailey et al., 2021). There's evidence that Health IT is most effective when applied in a way that reflects the different needs of patients, addresses cultural concerns, and provides tools in an appropriate language.</p>
                        <p>Some views might emphasize that such issues must be cleared up, and technology must be fixed for Telehealth to happen. Some points of view emphasize the urgency of training all the medical staff on how to operate telehealth technologies to perform their tasks effectively and engage more patients. In other words, one must emphasize the teaching and assistance provided to the patients who use online tools (Jonasdottir et al., 2022). Telehealth systems should contain such options as real-time feedback, which makes them even closer to continually improving based on actual users' needs and daily practice experience (Blecker et al., 2019).</p>
                        <h2>Conclusion</h2>
                        <p>The advantages of Telehealth include the fact that it is easily achievable, costs much less, makes patients happy, and provides better clinical outcomes as well. This proves how important it is in caring for patients with a very huge amount of data available to researchers. A few ways that a study can attempt to gain maximal results from Telehealth are through the use of open standards and the range of study methods. The rules are strict to ensure patients are safe, and risks are minimized; hence, patients' privacy is protected.</p>
                        <h2>References</h2>
                        <p>Bailey, J. E., Gurgol, C., Pan, E., Njie, S., Emmett, S., Gatwood, J., Gauthier, L., Rosas, L. G., Kearney, S. M., Robler, S. K., Lawrence, R., Margolis, K. L., Osunkwo, I., Wilfley, D., &amp; Shah, V. O. (2021). Using Telehealth to address disparities: A scoping review of early patient-centered outcomes research experience (preprint). <em>Journal of Medical Internet Research</em>, <em>23</em>(12).</p>
                        <p><a href="https://doi.org/10.2196/28503">https://doi.org/10.2196/28503</a></p>
                        <p>Blecker, S., Herrin, J., Li, L., Yu, H., Grady, J. N., &amp; Horwitz, L. I. (2019). Trends in hospital readmission of medicare-covered patients with heart&nbsp;failure. <em>Journal of the American College of Cardiology</em>, <em>73</em>(9), 1004&ndash;1012.</p>
                        <p><a href="https://doi.org/10.1016/j.jacc.2018.12.040">https://doi.org/10.1016/j.jacc.2018.12.040</a></p>
                        <p>Chen, F.-Q., Leng, Y.-F., Ge, J.-F., Wang, D.-W., Li, C., Chen, B., &amp; Sun, Z.-L. (2020). Effectiveness of virtual reality in nursing education: Meta-analysis. <em>Journal of Medical Internet Research</em>, <em>22</em>(9).</p>
                        <p><a href="https://doi.org/10.2196/18290">https://doi.org/10.2196/18290</a></p>
                        <p>Chinmoy, B., Ho, C. H., &amp; Brodell, R. T. (2020). Time to revisit HIPAA? Accelerated telehealth adoption during the COVID-19 pandemic. <em>Journal of the American Academy of Dermatology</em>, <em>83</em>(4).</p>
                        <p><a href="https://doi.org/10.1016/j.jaad.2020.06.989">https://doi.org/10.1016/j.jaad.2020.06.989</a></p>
                        <p>Dilick, M., &amp; Jones, T. (2022). <em>Determining the credibility of evidence &amp; resources: Making evidence-based decisions</em>.</p>
                        <p><a href="https://study-notes-pdfs.s3.us-west-2.amazonaws.com/38783817.pdf">https://study-notes-pdfs.s3.us-west-2.amazonaws.com/38783817.pdf</a></p>
                        <p>Esparrago-Kalidas, A. J. (2021). The effectiveness of the CRAAP test in evaluating the credibility of sources. <em>International Journal of TESOL &amp; Education</em>, <em>1</em>(2), 1&ndash;14.</p>
                        <p><a href="http://i-jte.org/index.php/journal/article/view/25">http://i-jte.org/index.php/journal/article/view/25</a></p>
                        <p>Faget, K., Lacktman, N. M., Joseph, J., &amp; Shalom, A. (2021). Telehealth legal and regulatory issues. <em>Telemedicine</em>, 15&ndash;31.</p>
                        <p><a href="https://doi.org/10.1007/978-3-030-64050-7_2">https://doi.org/10.1007/978-3-030-64050-7_2</a></p>
                        <p>Gordon, B., Mason, B., &amp; Smith, S. L. H. (2021). Leveraging Telehealth for delivery of palliative care to remote communities: A rapid review. <em>Journal of Palliative Care</em>, <em>37</em>(2).</p>
                        <p><a href="https://doi.org/10.1177/08258597211001184">https://doi.org/10.1177/08258597211001184</a></p>
                        <p>Haleem, A., Javaid, M., Singh, R. P., &amp; Suman, R. (2021). Telemedicine for healthcare: Capabilities, features, barriers, and applications. <em>Sensors International</em>, <em>2</em>(2).</p>
                        <p><a href="https://doi.org/10.1016/j.sintl.2021.100117">https://doi.org/10.1016/j.sintl.2021.100117</a></p>
                        <p>Harkey, L. C., Jung, S. M., Newton, E. R., &amp; Patterson, A. (2020). Patient satisfaction with Telehealth in rural settings: A systematic review. <em>International Journal of Telerehabilitation</em>, <em>12</em>(2), 53&ndash;64.</p>
                        <p><a href="https://doi.org/10.5195/ijt.2020.6303">https://doi.org/10.5195/ijt.2020.6303</a></p>
                        <p>Keenan, A. J., Tsourtos, G., &amp; Tieman, J. (2020). The value of applying ethical principles in telehealth practices: Systematic review. <em>Journal of Medical Internet Research</em>, <em>23</em>(3).</p>
                        <p><a href="https://doi.org/10.2196/25698">https://doi.org/10.2196/25698</a></p>
                        <p>Kristin Jonasdottir, S., Thordardottir, I., &amp; Jonsdottir, T. (2022). Health professionals? Perspective towards challenges and opportunities of telehealth service provision: A scoping review. <em>International Journal of Medical Informatics</em>, <em>167</em>.</p>
                        <p><a href="https://doi.org/10.1016/j.ijmedinf.2022.104862">https://doi.org/10.1016/j.ijmedinf.2022.104862</a></p>
                        <p>Omboni, S., Padwal, R. S., Alessa, T., Bencz&uacute;r, B., Green, B. B., Hubbard, I., Kario, K., Khan, N. A., Konradi, A., Logan, A. G., Lu, Y., Mars, M., McManus, R. J., Melville, S., Neumann, C. L., Parati, G., Renna, N. F., Ryvlin, P., Saner, H., &amp; Schutte, A. E. (2022). The worldwide impact of telemedicine during COVID-19: Current evidence and recommendations for the future. <em>Connected Health</em>, <em>1</em>.</p>
                        <p><a href="https://doi.org/10.20517/ch.2021.03">https://doi.org/10.20517/ch.2021.03</a></p>
                        <p>Phillips, T. A., Munn, A. C., &amp; George, T. P. (2020). Assessing the impact of Telehealth objective structured clinical examinations in graduate nursing education. <em>Nurse Educator</em>, <em>45</em>(3), 169&ndash;172.</p>
                        <p><a href="https://doi.org/10.1097/nne.0000000000000729">https://doi.org/10.1097/nne.0000000000000729</a></p>
                        <p>Ramaswamy, A., Yu, M., Drangsholt, S., Ng, E., Culligan, P. J., Schlegel, P. N., &amp; Hu, J. C. (2020). Patient satisfaction with telemedicine during the COVID-19 pandemic: A retrospective cohort study (preprint). <em>Journal of Medical Internet Research</em>, <em>22</em>(9).</p>
                        <p><a href="https://doi.org/10.2196/20786">https://doi.org/10.2196/20786</a></p>
                        <p>Schweiberger, K., Hoberman, A., Iagnemma, J., Schoemer, P., Squire, J., Taormina, J., Wolfson, D., &amp; Ray, K. N. (2020). Practice-Level variation in telemedicine use in a pediatric primary care network during the COVID-19 pandemic: Retrospective analysis and survey study. <em>Journal of Medical Internet Research</em>, <em>22</em>(12).</p>
                        <p><a href="https://doi.org/10.2196/24345">https://doi.org/10.2196/24345</a></p>
                        <p>Shimbo, D., Artinian, N. T., Basile, J. N., Krakoff, L. R., Margolis, K. L., Rakotz, M. K., &amp; Wozniak, G. (2020). Self-Measured blood pressure monitoring at home: A joint policy statement from the American Heart Association and American Medical Association. <em>Circulation</em>, <em>142</em>(4).</p>
                        <p><a href="https://doi.org/10.1161/cir.0000000000000803">https://doi.org/10.1161/cir.0000000000000803</a></p>
                        <p>Snoswell, C. L., Taylor, M. L., Comans, T. A., Smith, A. C., Gray, L. C., &amp; Caffery, L. J. (2020). Determining if Telehealth can reduce health system costs Scoping review<em>&mdash;Journal</em><em>&nbsp;of Medical Internet Research</em>, <em>22</em>(10).</p>
                        <p><a href="https://doi.org/10.2196/17298">https://doi.org/10.2196/17298</a></p>
                        <p>Souza, V. de L. e, Oliveira, I. A. G. de, Caetano, R., Ribeiro, G. da R., Santos, D. L., Silva, A. B., &amp; Silva, R. M. da. (2021). Conceptual frameworks used in evaluating telehealth initiatives: A scoping review protocol. <em>Research, Society and Development</em>, <em>10</em>(6).</p>
                        <p><a href="https://doi.org/10.33448/rsd-v10i6.15913">https://doi.org/10.33448/rsd-v10i6.15913</a></p>
                        <p>Springer, P., Bischoff, R. J., Kohel, K., Taylor, N. C., &amp; Farero, A. (2020). Collaborative care at a distance: Student therapists&rsquo; experiences of learning and delivering relationally focused telemental Health. <em>Journal of Marital and Family Therapy</em>, <em>46</em>(2), 201&ndash;217.</p>
                        <p><a href="https://doi.org/10.1111/jmft.12431">https://doi.org/10.1111/jmft.12431</a></p>
                        <p>White, S. J., Nguyen, A., Roger, P., Tse, T., Cartmill, J. A., &amp; Willcock, S. M. (2022). Experiences of Telehealth in general practice in Australia: Research protocol for a mixed-methods study. <em>British Journal of General Practice Open</em>, <em>6</em>(1).</p>
                        <p><a href="https://doi.org/10.3399/BJGPO.2021.0187">https://doi.org/10.3399/BJGPO.2021.0187</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Nursfpx5005assessment4
