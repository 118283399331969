import React from 'react'
import { Rating } from 'react-simple-star-rating';
import { SiTrustpilot } from "react-icons/si";
import { FaRegHandPointRight } from "react-icons/fa";
import { Link } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Stestimonials from './components/Stestimonials';
import Calltoaction from './components/Calltoaction';
import Calculator from '../.././homepage/Calculator';
import Container from 'react-bootstrap/esm/Container';
import { Accordion, AccordionBody, AccordionHeader, AccordionItem } from "react-headless-accordion";
import { TiArrowSortedDown } from "react-icons/ti";
import { Helmet } from 'react-helmet';

const Nurs4900capstoneprojectfornursing = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2023/10/Topmycourse.com_.png",
            title: "NHS FPX 6004 Assessment 1 Dashboard Metrics...",
            description: "Dashboard Metrics EvaluationHealthcare organizations and interprofessional teams...",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-5.png",
            title: "NURS FPX 4020 Assessment 4 Improvement Plan...",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources...",
            description: "Community ResourcesPublic health systems and community resources are critical in...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-15-1.png",
            title: "NURS FPX 6016 Assessment 1...",
            description: "Adverse Event or Near Miss AnalysisIn healthcare, adverse events and near-miss...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    const newServices = [
        {
            icon: "fa-solid fa-user-pen icon",
            title: "Best Tutoring Help",
            Desc: "We offer the best tutoring services and skills you need to succeed academically, from writing and research to data analysis and performance.",
        },
        {
            icon: "fa-solid fa-school icon",
            title: "Guidance with Literature Reviews",
            Desc: "To provide your project with a solid basis, our writer assists you in gathering and analyzing previous research on your selected topic through a comprehensive literature review.",
        },
        {
            icon: "fa-solid fa-book-open icon",
            title: "Final Formatting and Editing",
            Desc: "We offer careful editing and proofreading services to guarantee that your nursing project is error-free and follows the required format. ",
        },
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Comprehensive Research",
            Desc: "Our skilled writing experts can help you choose an attractive, timely study topic that fulfills your nursing aims and interests.",
        },
    ]

    const orderprocess = [
        {
            icon: "fa-solid fa-chalkboard-user icon",
            title: "Provide Guidelines",
            Desc: "Fill out the form with all the details.",
        },
        {
            icon: "fa-regular fa-money-bill-1 icon",
            title: "Make Payment",
            Desc: "Make your payment through a credit card and bank account.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Get experts",
            Desc: "Our writers will contact you and assist you in your assessments!",
        },
    ]

    const newFeatures = [
        {
            icon: "fa-solid fa-arrow-trend-up icon",
            title: "100% High Scores",
            Desc: "We have a Capella DNP FlexPath intellectuals team to help you get high scores.",
        },
        {
            icon: "fa-solid fa-clock-rotate-left icon",
            title: "24/7 Help",
            Desc: "We can always help you with your Capella DNP FlexPath Curriculum and coursework!",
        },
        {
            icon: "fa-solid fa-check-double icon",
            title: "No Plagiarism",
            Desc: "We offer plagiarism-free assessments to Capella University DNP FlexPath students.",
        },
        {
            icon: "fa-solid fa-person-chalkboard icon",
            title: "Best Guidance",
            Desc: "Seek complete support from Capella University DNP FlexPath nursing experts.",
        },
    ]
    return (
        <>
            <Helmet>
                <title>Academic Assignment Help</title>
                <meta name='description'
                    content='Struggling with nursing assignments? Get top-notch, affordable help 24/7 at Online Course Services. Tailored solutions & expert support just a click away.' />
                <meta name='keywords' content='help with nursing assignment, affordable assignment help, assignment help services, academic assignment help' />
            </Helmet>
            <div className='s-hero services'>
                {/* <div className='s-hero-top'>
    <div className='sherotop-left'>
    <span className='stop'>Take comprehensive help in Capella Flex path assessments</span>
    <h1>DO YOU WANT <br /> CAPELLA FLEX PATH ASSESSMENT HELP?</h1>
    <p>We have a team of Capella Flex Path nursing writers to help you with your Capella Flex Path assessments!</p>
    </div>
    <div className='cal-box'><Calculator/></div> 
</div> */}
                <div className='ocs2-main-box'>

                    <div className='ocs2-main' id='hero'>
                        <div className='ocs2-mainHeading'>
                            <h1><span className='ocs2-topHeading'>NURS4900 </span><br />CAPSTONE PROJECT FOR NURSING</h1>

                            <p className='ocs2-topText'>Are you having trouble with your a cappella nursing capstone project? Let our talented tutors help you and ensure the best possible submission.</p>
                            <ul>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Quality content without plagiarism</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Our top priority is your deadlines.</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Comprehensive Research</li>
                                <li><FaRegHandPointRight className='ocs2-list-icon' />&nbsp; Complete privacy</li>
                            </ul>
                            <div className='ocs2-banner-capella'>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Flex Path</h3>
                                        <p>Use your experience to complete courses.</p>
                                    </span>
                                </div>
                                <div className='ocs2-banner-capella-path'>
                                    <span>
                                        <h3 className='f-path-pointer'>Guided Path</h3>
                                        <p>Enjoy the flexibility to study anywhere.</p>
                                    </span>
                                </div>
                            </div>
                            <p>Trusted By 15K+ Students & <span className='ocs2-ratingCount'>Rated 4.8/5</span> Based on 9K+ Reviews</p>
                            <div className='ocs2-ratting'>
                                <div className='ocs2-ratting-provider'>
                                    <SiTrustpilot className='ocs2-trustpilot-icon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.7 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>TrustPilot</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/favicon.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.8 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>OnlineCourseServices</p>
                                    </div>
                                </div>
                                <div className='ocs2-ratting-provider'>
                                    <img src='images/sitejabber.png' alt='' className='ocs2-ratting-favicon' />
                                    <div>
                                        <span className='ocs2-ratting-stars'>4.9 <Rating name="half-rating-read" initialValue={5} fillColor='#1f323c' size={20} readonly /></span>
                                        <p>Sitejabber</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='ocs2-form'>
                            <Calculator />
                        </div>
                    </div>

                </div>
                <div className='s-cta'>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Hire Professionals</h2>
                            <p>Take help from professional tutors, mentors, and writers to excel in your Capella University DNP FlexPath coursework.</p>
                        </div>
                        <div className='s-cta-inner-btn'><Link to="/order" className='hirebtn'><button>Hire Expert</button></Link></div>
                    </div>
                    <div className='s-cta-inner'>
                        <div className='s-cta-inner-box'>
                            <h2>Get Nursing Papers</h2>
                            <p>Get excellent and well-researched Capella DNP FlexPath nursing papers!</p>
                        </div>
                        <div className='s-cta-inner-btn'><a href='https://onlinecourseservices.us/free-samples/' className='hirebtn'><button>Free Samples</button></a></div>
                    </div>
                </div>
            </div>


            <div className='freesamples services'>
                <h2>GET CAPELLA DNP FLEXPATH ASSESSMENT SOLUTIONS!</h2>
                <p>We provide excellent Capella University DNP FlexPath assessment solutions for students in their Capella DNP FlexPath Courses.</p>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-left'>
                    {newServices.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
                <div className='s-right'>
                    <span>Our Top-Notch Services</span>
                    <h2>NURS4900 CAPSTONE PROJECT FOR NURSING</h2>
                    <p>When it comes to picking a service that provides excellent help with nursing assignments, Online Course Services is the clear choice. We've been honing our services for over a decade. Our professionals go out of their way to customize solutions exactly as requested.
                        <br />
                        However, there are more reasons why nursing students consider us their top choice. Some of them include the following:
                        <br />
                        <ul>
                            <li>Comprehensive quality checks</li>
                            <li>Professional experts</li>
                            <li>Tailored solutions</li>
                            <li>Fast submissions</li>
                            <li>Student-friendly policies</li>
                            <li> Free access to samples</li>
                        </ul>
                    </p>
                    <div className='btn-box'><Link to='/order' className='hirebtn'><button>Hire Writer</button></Link></div>
                </div>
            </div>

            <div className='services cta'>
                <Calltoaction />
            </div>
            <div className='services'>
                <Tabs>
                    <TabList>
                        <Tab><strong>Capella Nursing Capstone Project</strong></Tab>
                        <Tab><strong>BSN Capstone Project Proposal Capella Assistance</strong></Tab>
                        <Tab><strong>Capella BSN Capstone Practicum</strong></Tab>
                    </TabList>

                    <TabPanel>
                        <p>Do you need help keeping up with the demands of your Capella Nursing Capstone Project? To help you succeed in this project, our Online Course Services provide comprehensive support.</p>
                        <p>Our qualified team of nursing specialists is committed to offering your Capella Nursing Capstone Project complete support. We provide full support by choosing an appropriate study topic to write an organized paper and giving an engaging presentation. To help nursing students succeed in their capstone projects, we provide complete support. Since we are aware of Capella University capstone resources, our staff of skilled writers and nurses is committed to offering individualized support and guidance along the course of the project. Your Capella nursing capstone project will be designed to your unique requirements and interests thanks to our individualized approach.</p>
                        <p>We guarantee a smooth and successful completion of your BSN Capstone Course Capella with our full support. BSN students can get expert direction at every stage, from choosing a topic and conducting research to writing and editing. Our team of skilled nursing professionals is committed to assisting you in reaching your academic objectives and creating a superior capstone project that showcases your abilities and expertise.</p>
                    </TabPanel>
                    <TabPanel>
                        <p>The Online Course Services team of nursing experts offers assistance in creating original and significant Capella BSN capstone project ideas. We provide individualized advice, brainstorming sessions, and comprehensive research support to make sure your project fits with your hobbies and professional objectives.</p>
                        <p>Furthermore, you can Get help in crafting a compelling and engaged BSN capstone project proposal Capella. We offer thorough assistance, from choosing an appropriate study topic to creating a solid technique. We'll collaborate closely with you to choose an engaging and significant issue that will have a long-lasting impact on the nursing profession, whether your focus is clinical practice, healthcare policy, or nursing education. Online Course Services will assist you in properly organizing your proposal, making sure it contains all required aspects. It includes a precise research question, goals, technique, and expected results.</p>
                    </TabPanel>
                    <TabPanel>
                        <p>We are aware of how important the Capella BSN Capstone Practicum is to complete your nursing degree while setting you up for professional success. Online Course Services all-inclusive services are intended to help you throughout this important stage of your academic career. We provide personalized advice and support to help you find a practicum that fits your needs and career objectives. Our knowledgeable staff will assist BSN students with creating a thorough practicum plan that details their learning goals, exercises, and assessment standards. So, you can get continuous assistance, guidance, and feedback during your practicum to make sure you are fulfilling program requirements and taking full advantage of your educational opportunities.</p>
                    </TabPanel>
                </Tabs>
            </div>

            <div className='process services freesamples'>
                <h2>HOW IT WORKS?</h2>
                <p>Feel free to seek help from Capella Flex Path assessment writers. We will offer the best assessment solutions.</p>
                <div className='processboxes'>
                    {orderprocess.map((order) => {
                        return (

                            <div className='singleprocess'>
                                <i class={order.icon}></i>
                                <h3>{order.title}</h3>
                                <p>{order.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>


            <div className='services s-section'>
                <div className='s-right'>
                    <span>Our Features</span>
                    <h2>Why Choose Us For Capella Nursing Capstone Project Help?</h2>
                    <p>Are you able to demonstrate your proficiency in nursing and complete an outstanding capstone project to wrap up your academic career? Our goal at Online Course Services is to support BSN students in achieving academic success and getting ready for a lucrative profession. We provide extensive training to our tutors to help students highest grades. Also, you can check their sample project before hiring them.</p>
                    <div><button>Hire Writer</button></div>
                </div>
                <div className='s-left'>
                    {newFeatures.map((newserivce) => {
                        return (
                            <div className='s-single'>
                                <i class={newserivce.icon}></i>
                                <h3>{newserivce.title}</h3>
                                <p>{newserivce.Desc}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <Stestimonials />
            <Container>
                <div className='ocs2-faqs'>
                    <h2 className='ocs2-faqs-title'>FREQUENTLY ASKED QUESTIONS</h2>
                    <div className='ocs2-faqs-boxes'>
                        <div className='accordian-box-image'>
                            <img src='images/faqs.png' alt='' />
                        </div>

                        <Accordion className='accordian-boxes'>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>What is the NURS4900 Capstone Project?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        <p>As a final academic task, the NURS4900 Capstone Project gives nursing students the chance to show off their understanding, proficiency, and ability to apply nursing concepts to practical settings.</p>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How can I get Capella nursing capstone project assistance?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        <p>Online Course Services offer nursing capstone project help. You can hire our tutors to get help in BSN projects.</p>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Do Online Course services offer Capella BSN capstone project ideas?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        <p>The team of nursing professionals at Online Course Services can help you come up with unique and impactful ideas for your Capella BSN capstone project.</p>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>How Do I Develop a Powerful Proposal for a Capstone Project?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        <p>Our expert tutors help you in crafting a compelling and engaged BSN capstone project proposal, Capella.</p>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader className='accordian-header'>
                                    <h3 className={`accordion-title`}>Can I edit the subject of my capstone project while it's being done?</h3>
                                    <TiArrowSortedDown className='accordian-header-icon' />
                                </AccordionHeader>

                                <AccordionBody className='accordian-body-ans'>
                                    <div className="accordion-body">
                                        <p>As you learn more about the topic, it is normal to modify the topic of your capstone project. Make sure your new topic is still practical and relevant, however, and let your faculty mentor know about any modifications.</p>
                                    </div>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </Container>
        </>

    )
}

export default Nurs4900capstoneprojectfornursing
