import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Socfpx2000assessment3 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>SOC FPX 2000 Assessment 3 Social Activism and Data Analysis</title>
                <meta name='description'
                    content=' SOC FPX 2000 Assessment 3 Social Activism and Data Analysis - Leverage data for social change. Read our guide now!' />
                <meta name='keywords' content='SOC FPX 2000 Assessment 3 Social Activism and Data Analysis' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>SOC FPX 2000 Assessment 3 < br /><span>Social Activism and Data Analysis</span></h1>

                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/SOCFPX2000Assessment3.webp" alt="" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>SOC FPX 2000 Assessment 3 Social Activism and Data Analysis.</strong></em></li>
                        </ul>
                        <h2>Position Paper Overview</h2>
                        <p>This paper targets identifying the institutional discrimination that minority gatherings face in the US criminal justice system. This paper will separate the racial and ethnic inconsistencies within the US criminal justice system and assess these aberrations' impacts on these affiliations.</p>
                        <p>The influence of power on authentic and current techniques will show the underlying issue that power makes minority inconsistencies. Finally, the proposed approach and definitive changes will be kept an eye out for the absolute intent of discovering the serious outcomes regarding the influence of the discrimination looked at by these affiliations.</p>
                        <h2>Discrimination in the Criminal Justice System</h2>
                        <p>The US has a long history of enthusiasm embedded in its kin, strategies, and institutions. From mass incarceration, misuse, post-conviction predisposition, and informal guidelines and procedures, dull Americans and other minority packs are at a disproportionately high mischief in our criminal justice system.</p>
                        <p>As ought to be conspicuous in later political choice years and all through the Covid pandemic, key moves a monster work in shifting the dynamic of the US public, and legislation made to help with social issues, such as wrongdoing and arrangements, for the most part, bring about essential stumbles for dull Americans.</p>
                        <p>Financial aberrations influence faint individuals' mental and genuine thriving (Hamilton and Roy, 2020). Faint Americans have lived with these distinctions for quite a while, and as shown in Figure 1, expansive stretches of systematic abuse have incited increased well-being concerns and mental mischief (Winters, 2020).</p>
                        <p>(Winters, 2020)</p>
                        <img src="images/Picture1.png" alt=''/>
                        <p>While white individuals make up 41% of disastrous shootings achieved by police, they contain over 60% of everybody, except dull Americans make up 13.4 percent of everyone, yet 22% of dangerous police shootings (NAACP, 2021). The diagram highlights the quantifiable information on police killings between 2017-2021.</p>
                        <p>(NAACP,2021)</p>
                        <img src="images/Picture2.png" alt=''/>
                        <p>98 police have been gotten since 2005 regarding risky shootings of unarmed inhabitants. Of these 98, only 35 have truly been indicted for a wrongdoing, altogether more so the lesser offense of crime, rather than first-or second-degree murder. With the mishap of responsibility regarding police, while committing such shows, most faint occupants live in misgiving about customary presence, whether in a general sense walking not exorbitantly distant, going shopping for food, or on any occasion, driving to an appointment.</p>
                        <p>(NAACP, 2021) Notwithstanding the way that there is an issue of police shootings involving unarmed faint occupants, mass incarceration in like way prompts poor mental and genuine well-being, increases substance abuse, and leaves individuals trapped in a generational delineation of pain. Incarcerated individuals are more against finding stable work once they are let out of prison. Bases show that the likelihood of receiving a re-appearance from a potential business is diminished extensively (Soloman, 2012).</p>
                        <h3>SOC FPX 2000 Assessment 3 Social Activism and Data Analysis</h3>
                        <p>African Americans address, all things considered, 28% of all gets and 40 per cent of current jail individuals (Soloman, 2012). The deficiency of progress and resources for individuals struggling with substance abuse, mental or genuine well-being, and encountering the insidious impacts of vagrancy is fundamental. The ongoing prison system is fanned out upon discipline and transport. Without a lack of resources to help individuals, the greater part is reincarcerated after release; there are basically 50,000 genuine constraints against those with prior convictions (NAACP, 2021).</p>
                        <p>Racial discrimination in America ought to be clear commonly around the whole course of everything working out. There is a concise system of misuse that adds to the racially coordinated improvement that continues to allow the maltreatment of dull individuals (Hamilton and Roy, 2020). Enthusiasm isn't limited to the general individuals. Enthusiasm is installed within neighbours, instructors, and officials, and most importantly, predisposition is infiltrating the US criminal justice system, where aficionado individuals are making decisions and taking action against minority gatherings.</p>
                        <h2>Contributing Policies and Influence of Power</h2>
                        <p>In 1970, President Richard Nixon connoted the Controlled Substances Act (CSA) and expressed that individuals, in general, "Fight on Medications." Government funding was increased for affiliations focused on consistent medicine use therapy, and the Arrangement Support Administration (DEA) was spread out. Close to these things, Nixon furthermore made the Counter Unlawful Solution use the 1986 method, which spread out mandatory minimum sentences for drug offences (Langner and Zajicek, 2017).</p>
                        <p>These policies and political systems instilled racial feelings of fear in hopeless white Southerners, considered the "Southern Strategy," which convinced these individuals to project a voting structure against their gathering interests (Langner and Zajicek, 2017). In the context of the battle for ineffective wrongdoing decline policies, Congress, close by 25 other states, passed "Three Strike Guidelines."</p>
                        <h3>SOC FPX 2000 Assessment 3 Social Activism and Data Analysis</h3>
                        <p>This adaption of three-strike guidelines increased more ridiculous sentences for individuals with prior convictions, which include life sentences without the opportunity for further interest for those with three wrongdoing convictions (Kovandzic et al., 2004). These guidelines and policies increased incarceration and life sentences for African American people groups, who oftentimes had "major" drug charges that cultivated genuine offence feelings. As tracked down in the chart below, the need for substance motivation increased enormously in haziness individuals versus white individuals.</p>
                        <img src='images/Picture3.png' alt=''/>
                        <p>(Rosino &amp; Hughey, 2018)</p>
                        <p>The War on Drugs and Three Strike laws immensely contributed to the racialized mass incarceration of black people. Rosino and Hughey (2018) found that the mass incarceration of minority groups &ldquo;disproportionately and negatively affects the store of cultural, social, economic, and symbolic capital within many low-income black and Latinx families." Families face strain due to lack of internal and community-based resources that have been constricted along racial lines by institutional practices and other methods of systematic racism (Rosino &amp; Hughey, 2018).</p>
                        <p>These laws and policies were not meant to make society stronger; they were rooted in systematic control of poor, minority groups and political power movements. The War on Drugs was heavily influenced by Richard Nixon's political power and movement. This shifted white voters who were influenced by racial fears. In more current times, the presidential elect of 2016, Donald Trump, is another example of how political power can shift society as a whole and create a flood of chaos and racial dilemmas.</p>
                        <p>American culture is heavily embedded with racism and white supremacy, and Donald Trump's political campaign mobilized the white supremacist rhetoric. Trump successfully fueled the working class and exploited anxiety regarding ethnic diversity in America. With a campaign full of demonizing and stereotyping minority groups, Trump appealed to the deeply racist Southern white population with the promise of &ldquo;Make American Great Again.&rdquo;</p>
                        <p>This political campaign signalled the effort to return American society to a time when white citizen privilege and well-being would be protected. (Bobo, 2017) Political campaigns and governing bodies have a significant influence on the policies and laws that are instilled in America, as seen with Nixon's War on Drugs. These policies create a dangerous environment for minority groups and are often times shadowed by the promise of safer communities. However, it is important to re-examine current laws and legislation to ensure the lives and safety of minority groups are protected.</p>
                        <h2>Solutions</h2>
                        <p>Racial disparities within the criminal justice system are deeply rooted and complex. Indicating that there is no issue with the current system after centuries of racist history blinds decision-makers to the racial bias that is deeply embedded in America's formal policies. There are many measures that can work to reduce the effects of racial bias in the criminal justice system, such as ending the War on Drugs, eliminating mandatory minimum sentences, and developing required racial bias training at all levels of the criminal justice system. (The Sentencing Project, 2018) Ending the War on Drugs would substantially decrease the number of minority groups incarcerated for low-level drug charges (The Sentencing Project, 2018).</p>
                        <p>These low-level charges should not be prosecuted by the federal court, and local law enforcement agencies would be able to reduce the number of arrests for minor drug offences. Decreasing the number of prosecutions would increase resources that can be invested in treatment and drug prevention measures (The Sentencing Project, 2018). An increase in community resources can open opportunities for those seeking to beat their drug addiction, rather than ending up in prison and lowering their chance of housing and employment once released.</p>
                        <h3>SOC FPX 2000 Assessment 3 Social Activism and Data Analysis</h3>
                        <p>Ending mandatory minimum sentences would enable judges to consider case-by-case when sentencing a defendant. Transparency is lost when there are mandatory sentences; it shifts the power from judges to prosecutors, which does not aid in eliminating discretion (The Sentencing Project, 2018). With mandatory minimum sentences, judges cannot consider unique circumstances or facts. Minimum sentences do not aid in keeping the public safe, in fact mandatory minimum sentences lead to increased convictions, overcrowded prisons, and higher costs for taxpayers (FAMM, 2018).</p>
                        <p>Lastly, implementing a training program at all levels of the criminal justice system (police officers, prosecutors, judges, etc) can aid in reducing implicit racial bias in individual decision-making (The Sentencing Project, 2018). While it is incredibly difficult and somewhat impossible to eliminate racism, training and education completely can help train individuals to be behaviorally colourblind (The Sentencing Project, 2018).</p>
                        <p>In 2005, police officers observed on a computer simulation their decision-making when shooting black and white criminal suspects (Plant &amp; Peruche, 2005). Responses showed that officers were much more likely to shoot unarmed black suspects compared to white suspects. As a result, researchers implemented a training program that positively showed a decrease in racial biases in which the race of the suspect was unrelated to the decision-making aspect (Plant &amp; Peruche, 2005).</p>
                        <h2>Conclusion</h2>
                        <p>The American criminal justice system is deeply rooted in systematic racism that leads to higher incarceration rates of blacks and other minorities, higher police brutality incidences, and policies and laws that only contribute to the disparities poor minority communities face. Political power influences decision-making, and electoral candidates have an immense influence on the deeply embedded white supremacy rhetoric.</p>
                        <p>The criminal justice system was supposed to be founded on reform and proper readmission to the general public, however current policies and lack of community-based resources make it nearly impossible for minority groups to seek employment, affordable housing, and substance abuse treatment after being incarcerated. It is time to reevaluate our current legislation and policies and develop a criminal justice system that serves to protect all Americans.</p>
                        <h2>References</h2>
                        <p>Bobo, L. D. (2017). Racism in Trump&rsquo;s America: reflections on culture, sociology, and the 2016 US presidential election. <em>The British Journal of Sociology</em>, <em>68</em>(S1).</p>
                        <p><a href="https://doi.org/10.1111/1468-4446.12324">https://doi.org/10.1111/1468-4446.12324</a></p>
                        <p>FAMM. (2018, April 6). <em>Mandatory Minimum Repeal</em>.</p>
                        <p><a href="https://famm.org/our-work/u-s- congress/repeal/">https://famm.org/our-work/u-s- congress/repeal/</a></p>
                        <p>Hamilton, J. C., &amp; Roy, D. R. (2020). <em>When They See Us</em>: An Unshaken History of Racism in America. <em>Journal of Family Theory &amp; Review</em>, <em>12</em>(1), 100&ndash;107.</p>
                        <p><a href="https://doi.org/10.1111/jftr.12360">https://doi.org/10.1111/jftr.12360</a></p>
                        <p>Kovandzic, T. V., Sloan, J. J., &amp; Vieraitis, L. M. (2004). &ldquo;Striking out&rdquo; as crime reduction policy: The impact of &ldquo;three strikes&rdquo; laws on crime rates in U.S. cities. <em>Justice Quarterly</em>, <em>21</em>(2), 207&ndash;239.</p>
                        <p><a href="https://doi.org/10.1080/07418820400095791">https://doi.org/10.1080/07418820400095791</a></p>
                        <p>Langner, J., &amp; Zajicek, A. (2017). The social construction of drug policies and target populations:</p>
                        <p>U.S. Policy and Media Discourse. <em>Acta Universitatis Lodziensis. Folia Sociologica</em>, <em>62</em>, 47&ndash;62.</p>
                        <p><a href="https://doi.org/10.18778/0208-600x.62.04">https://doi.org/10.18778/0208-600x.62.04</a></p>
                        <p>NAACP. (2021, May 25). <em>Criminal Justice Fact Sheet</em>.</p>
                        <p><a href="https://naacp.org/resources/criminal- justice-fact-sheet">https://naacp.org/resources/criminal- justice-fact-sheet</a></p>
                        <p>Plant, E. A., &amp; Peruche, B. M. (2005). The Consequences of Race for Police Officers Responses to Criminal Suspects. <em>Psychological Science</em>, <em>16</em>(3), 180&ndash;183.</p>
                        <p><a href="https://doi.org/10.1111/j.0956-7976.2005.00800.x">https://doi.org/10.1111/j.0956-7976.2005.00800.x</a></p>
                        <p>Rosino, M. L., &amp; Hughey, M. W. (2018). The War on Drugs, Racial Meanings, and Structural Racism: A Holistic and Reproductive Approach. <em>American Journal of Economics and Sociology</em>, <em>77</em>(3&ndash;4), 849&ndash;892.</p>
                        <p><a href="https://doi.org/10.1111/ajes.12228">https://doi.org/10.1111/ajes.12228</a></p>
                        <p>Soloman, A. J. (2012, June 14). <em>In Search of a Job: Criminal Records as Barriers to Employment</em>. National Institute of Justice.</p>
                        <p><a href="https://nij.ojp.gov/topics/articles/search-job- criminal-records-barriers-employment">https://nij.ojp.gov/topics/articles/search-job- criminal-records-barriers-employment</a></p>
                        <p>The Sentencing Project. (2018, April). <em>Report to the United Nations on Racial Disparities in the</em></p>
                        <p><em>U.S. Criminal Justice System</em>.</p>
                        <p><a href="https://www.sentencingproject.org/publications/un-report-on-racial-disparities/">https://www.sentencingproject.org/publications/un-report-on-racial-disparities/</a></p>
                        <p>Winters, M. (2020). <em>Black Fatigue: How Racism Erodes the Mind, Body, and Spirit </em>(Illustrated ed.). Berrett-Koehler Publishers.</p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Socfpx2000assessment3
