import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Psycfpx3500assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet</title>
                <meta name='description'
                    content='Struggling with PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet? Our worksheet provides clear guidelines and examples to help you succeed.' />
                <meta name='keywords' content='PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>PSYC FPX 3500 Assessment 1 < br /><span>Popular Versus Scholarly Sources Worksheet</span></h1>

                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/PSYCFPX3500Assessment1.png" alt="" />
                    <div className='main-container-sl-content'>
                        <ul>
                            <li><em><strong>PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet.</strong></em></li>
                        </ul>
                        <h2>Popular Versus Scholarly Sources Worksheet</h2>
                        <ul>
                            <li>
                                <h3>Abstract</h3>
                            </li>
                        </ul>
                        <p>The primary goal of this research is to investigate the cognitive psychology aspect of the role of cognitive therapy in treating eating disorders. To explore this topic, a significant media source, "Food for thought cognitive behavioural therapy (CBT) for eating disorders," is examined to extract important case data. The study will focus on the role of CBT in treating eating disorders, particularly anorexia nervosa and binge eating disorder.</p>
                        <p>Scholarly sources will be used to assess the effectiveness of CBT, including its long-term impacts on recovery and its adaptability across various eating disorder diagnoses. Furthermore, the reliability of both sources is reinforced by their alignment with popular media discussions, which emphasizes the practical applications of CBT and its benefits in managing cognitive distortions related to eating. The sources provide a comprehensive overview of how CBT can effectively contribute to treating eating disorders in clinical settings.</p>
                        <h2>Media Evaluation</h2>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Directions</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Responses</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Name your selected topic.</p>
                                        <p>Provide the full reference for the source, and include the link to the video or website.</p>
                                    </td>
                                    <td>
                                        <p>Topic: The cognitive psychology aspect of the role of cognitive therapy in treating eating disorders.</p>
                                        <p>AED Archive. (2020, November 13). <em>Food for thought Cognitive behavioural therapy for eating disorders</em>. YouTube.</p>
                                        <p><a href="https://youtu.be/uAnGgcT97m0">https://youtu.be/uAnGgcT97m0</a></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Summarize the content of the source using complete sentences in a paragraph of 5&ndash;8 sentences.</p>
                                    </td>
                                    <td>
                                        <p>The video "Food for Thought Cognitive Behavioural Therapy for Eating Disorders" by AED Archive (2020) discusses how CBT is used to treat individuals suffering from eating disorders, such as anorexia nervosa and bulimia nervosa. The video explains that CBT focuses on identifying and altering unhealthy thoughts and behaviours related to food, body image, and self-worth. Therapists work with patients to challenge distorted beliefs and help them develop healthier eating patterns. Key strategies include keeping food diaries, understanding emotional triggers, and practising techniques to manage distress without resorting to harmful behaviours like binge eating or purging. The video emphasizes that CBT is one of the most effective treatments for eating disorders, promoting long-term recovery through structured, goal-oriented sessions.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>List three peer-reviewed journal articles on the topic providing and explaining the relevance of each of the articles to the topic.</p>
                                        <p>Provides the full references in APA format.</p>
                                    </td>
                                    <td>
                                        <p>Fairburn, C. G., Cooper, Z., &amp; Shafran, R. (2023). Cognitive behaviour therapy for eating disorders: A transdiagnostic theory and treatment. <em>Behaviour Research and Therapy</em>, <em>41</em>(5), 509&ndash;528.</p>
                                        <p><a href="https://doi.org/10.1016/s0005-7967(02)00088-8">https://doi.org/10.1016/s0005-7967(02)00088-8</a></p>
                                        <p>The article presents a foundational cognitive-behavioural approach that applies to different types of eating disorders, including anorexia and bulimia. This article is relevant because it supports the use of a transdiagnostic model in therapy, an idea explored in many media discussions about effective treatment strategies for diverse eating disorders. The video also touches on this by highlighting how CBT addresses various disordered thoughts and behaviors, making it adaptable for treating multiple conditions and reinforcing the transdiagnostic perspective. Furthermore, both the video and the article stress that CBT helps patients develop long-term resilience against relapse by focusing on cognitive restructuring, a key benefit underscored by research and clinical practice.</p>
                                        <p>Russell, H., Aouad, P., Le, A., Foroughi, N., Fuller-Tyszkiewicz, M., Anthea Fursland, Gonzalez-Arce, V., Gouldthorp, B., Griffin, K., Griffiths, S., Hambleton, A., Hannigan, A., Hart, M., Hart, S., Hay, P., Hickie, I. B., King, R., Kohn, M., Krug, I., &amp; Linardon, J. (2023). Psychotherapies for eating disorders: Findings from a rapid review. <em>Journal of Eating Disorders</em>, <em>11</em>(1), 8&ndash;12.</p>
                                        <p><a href="https://doi.org/10.1186/s40337-023-00886-w">https://doi.org/10.1186/s40337-023-00886-w</a></p>
                                        <p>The study reviews the success of CBT in treating binge eating disorder over the long term, which is often a focus in media discussions about sustainable recovery from eating disorders using cognitive therapy. This is consistent with the video&rsquo;s emphasis on CBT's ability to help individuals manage emotional triggers and develop healthier coping mechanisms to prevent relapse. Both sources highlight how CBT provides practical tools for patients to regulate their eating behaviors, making it one of the most effective treatments for binge eating disorder. Moreover, the long-term efficacy discussed in the study mirrors the video&rsquo;s portrayal of CBT as a sustainable and adaptive treatment, capable of addressing the core cognitive issues that fuel disordered eating.</p>
                                        <p>Galsworthy, L., &amp; Allan, S. (2024). Cognitive behavioural therapy for anorexia nervosa: A systematic review. <em>Clinical Psychology Review</em>, <em>34</em>(1), 54&ndash;72.</p>
                                        <p><a href="https://doi.org/10.1016/j.cpr.2013.11.001">https://doi.org/10.1016/j.cpr.2013.11.001</a></p>
                                        <p>This systematic review evaluates the effectiveness of CBT in treating anorexia nervosa, a frequently mentioned topic in media videos covering cognitive therapy&rsquo;s impact on severe eating disorders. The video underscores CBT&rsquo;s role in helping patients challenge perfectionistic thoughts about food and body image, which is central to the treatment of anorexia nervosa, aligning with the findings of this review. Both the review and the video agree that, despite challenges, CBT offers valuable strategies to address the rigid cognitive patterns that often maintain the disorder. Moreover, the video highlights the importance of long-term recovery, which is supported by the review's findings that CBT can help patients sustain healthier behaviors beyond the initial treatment phase.</p>
                                        <h3>PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet</h3>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Compare and contrast information provided in the source with each of the three articles by answering these questions and citing the sources:</p>
                                    </td>
                                    <td>
                                        <p>&middot; What information provided by the popular source is supported by research or information provided in the articles?</p>
                                        <p>The video highlighted the effectiveness of CBT for treating eating disorders like anorexia nervosa and bulimia nervosa. This is strongly supported by research in the articles, such as Fairburn et al. (2023), which emphasizes the transdiagnostic model of CBT. This model applies CBT across different eating disorder diagnoses, confirming the video's claim that CBT is a versatile and effective treatment option. The idea that CBT focuses on changing unhealthy thoughts and behaviors, as mentioned in the video, aligns with the article by Galsworthy and Allan (2024), which reviews CBT's success in addressing the distorted cognitive processes underlying anorexia nervosa. Both the video and Russell et al. (2023) mention that CBT has long-term efficacy, especially in treating binge eating disorders, supporting the video's narrative on the sustainable impact of CBT on eating disorder recovery.</p>
                                        <p>&middot;What if any information in the articles conflicts with the popular source?</p>
                                        <p>While the video presents CBT as uniformly effective for all eating disorders, Galsworthy and Allan (2024) point out some limitations in its effectiveness, particularly in anorexia nervosa, where recovery rates are not as robust as in other disorders like bulimia. This nuance is not as clearly articulated in the media source. Additionally, the video may oversimplify the role of CBT by focusing mainly on cognitive aspects, whereas the articles delve into more complex therapeutic mechanisms like <em>inhibitory learning</em> and <em>exposure therapy</em>, which are vital for treating certain subtypes of eating disorders.</p>
                                        <p>&middot;Do you consider the media source you selected to be valid? Why or why not?</p>
                                        <p>Yes, the media source is valid to an extent. It provides an accessible and accurate overview of CBT's role in treating eating disorders, focusing on practical therapy elements and explaining its broad applications. However, it lacks the depth and detail that peer-reviewed research provides, especially regarding the specific challenges and limitations of CBT for certain eating disorder subtypes. Therefore, while valid, the video would benefit from incorporating more complex insights from scholarly research (Russell et al., 2023).</p>
                                        <p>&middot;What questions do you have about the topic based on your analysis?</p>
                                        <p>How can the limitations of CBT for anorexia nervosa, as highlighted by some research, be addressed in clinical practice?</p>
                                        <p>Given the promising results of inhibitory learning and exposure therapy, how are these newer methods being integrated into standard CBT protocols for eating disorders?</p>
                                        <p>What are the factors that influence long-term success in CBT treatment for patients with more complex eating disorders, like ARFID or those with comorbid trauma?</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Explain how the topic can be used to help solve problems in the real world.</p>
                                        <p>Then, illustrate with personal or professional examples.</p>
                                    </td>
                                    <td>
                                        <p>The application of CBT for treating eating disorders has substantial real-world potential in addressing significant public health challenges. Eating disorders such as anorexia nervosa, bulimia nervosa, and binge eating disorder are not only life-threatening but also present long-term mental health complications (Russell et al., 2023). By using CBT, healthcare professionals can help patients recognize and modify dysfunctional thought patterns about food, body image, and self-worth, which are central to these disorders. CBT equips individuals with the skills to challenge distorted beliefs and adopt healthier behaviors, promoting sustainable recovery and reducing the risk of relapse.</p>
                                        <h3>PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet</h3>
                                        <p>In professional settings, CBT has been widely adopted for treating a range of eating disorders. For example, mental health practitioners in clinical settings use CBT to help patients with anorexia nervosa reframe perfectionistic thoughts about weight and food, which are often barriers to recovery (Russell et al., 2023). Similarly, for patients with bulimia nervosa, CBT can help reduce binge-purge cycles by encouraging the recognition of emotional triggers that lead to these behaviors (Russell et al., 2023). The study by Russell et al. (2023) shows that CBT remains effective even after treatment, emphasizing its role in long-term care plans</p>
                                        <p>In professional practice, CBT interventions also play a crucial role in multidisciplinary teams, including nutritionists and medical doctors, who collaborate to provide comprehensive care. Additionally, CBT can be applied in preventative frameworks. For example, schools and community health organizations can offer CBT-based psychoeducation programs to adolescents at risk of developing eating disorders, providing tools to manage body image pressures and prevent the escalation of disordered eating behaviors (Galsworthy &amp; Allan, 2024). By integrating CBT into mental health interventions across healthcare and educational settings, the therapy not only addresses existing disorders but also helps reduce the incidence and severity of eating disorders in broader populations.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>Self-Evaluation</h2>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td>
                                        <p><strong>Criterion</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Level (Nonperformance, Basic, Proficient, or Distinguished)</strong></p>
                                    </td>
                                    <td>
                                        <p><strong>Rationale</strong></p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Summarizes the content of a popular media source, conveying the implications of the topic.</p>
                                    </td>
                                    <td>
                                        <p>Distinguished</p>
                                    </td>
                                    <td>
                                        <p>Clearly summarized the content by effectively capturing the video's main points on CBT's role in treating eating disorders, highlighting its practical implications and the importance of cognitive restructuring in therapy.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Identifies three peer-reviewed journal articles on the topic covered in a popular media source and describes the relevancy of the articles. Provides the full references of the articles in APA format.</p>
                                    </td>
                                    <td>
                                        <p>Distinguished</p>
                                    </td>
                                    <td>
                                        <p>Three relevant peer-reviewed journal articles were identified, showcasing a strong connection to the media source. The APA citations are complete and accurate, enhancing the credibility of the sources.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Compares and contrasts information provided in a popular media source with peer-reviewed journal articles, making a clear case for the media source's validity or lack of validity.</p>
                                    </td>
                                    <td>
                                        <p>Proficient</p>
                                    </td>
                                    <td>
                                        <p>A well-rounded comparison was made, highlighting similarities and differences between the media source and the peer-reviewed articles, particularly regarding the effectiveness of CBT and its limitations, affirming the validity of the media source.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Describes how the topic of a popular media source can be applied to the real world, providing examples.</p>
                                    </td>
                                    <td>
                                        <p>Proficient</p>
                                    </td>
                                    <td>
                                        <p>The application of CBT in real-world scenarios was effectively illustrated, with examples from clinical practice and preventive frameworks, showcasing its broad relevance to addressing eating disorders in various settings.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Conducts a self-evaluation using established scoring criteria and identifying the proficiency level for each criterion, including comments for each criterion.</p>
                                    </td>
                                    <td>
                                        <p>Distinguished</p>
                                    </td>
                                    <td>
                                        <p>A comprehensive self-evaluation was conducted, reflecting on the strengths and areas for improvement in the analysis. Clear and constructive feedback was provided for each criterion, demonstrating a thoughtful approach to self-assessment.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Presents a focused purpose through strong organizational skills. Presents evidence through strong paraphrasing or summarizing and appropriate tone and sentence structure.</p>
                                    </td>
                                    <td>
                                        <p>Distinguished</p>
                                    </td>
                                    <td>
                                        <p>The writing is well-organized and coherent, with a focused purpose. The evidence is presented clearly through effective paraphrasing and summarizing, maintaining an appropriate academic tone throughout.</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Applies current APA style, including in-text citations and full references for sources with few errors.</p>
                                    </td>
                                    <td>
                                        <p>Distinguished</p>
                                    </td>
                                    <td>
                                        <p>All sources, including in-text citations, were correctly cited using the APA format. All information&nbsp;was presented in Times New Roman, 12-point type, with no errors.</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>References</h2>
                        <p>AED Archive. (2020, November 13). <em>Food for thought Cognitive behavioral therapy for the eating disorders</em>. YouTube.</p>
                        <p><a href="https://youtu.be/uAnGgcT97m0">https://youtu.be/uAnGgcT97m0</a></p>
                        <p>Fairburn, C. G., Cooper, Z., &amp; Shafran, R. (2023). Cognitive behaviour therapy for eating disorders: A transdiagnostic theory and treatment. <em>Behaviour Research and Therapy</em>, <em>41</em>(5), 509&ndash;528.</p>
                        <p><a href="https://doi.org/10.1016/s0005-7967(02)00088-8">https://doi.org/10.1016/s0005-7967(02)00088-8</a></p>
                        <h3><a href="https://www.writinkservices.com/psyc-fpx-3500-assessment-1-popular-versus-scholarly-sources/">PSYC FPX 3500 Assessment 1 Popular Versus Scholarly Sources Worksheet</a></h3>
                        <p>Galsworthy, L., &amp; Allan, S. (2024). Cognitive behavioural therapy for anorexia nervosa: A systematic review. <em>Clinical Psychology Review</em>, <em>34</em>(1), 54&ndash;72.</p>
                        <p><a href="https://doi.org/10.1016/j.cpr.2013.11.001">https://doi.org/10.1016/j.cpr.2013.11.001</a></p>
                        <p>Russell, H., Aouad, P., Le, A., Foroughi, N., Fuller-Tyszkiewicz, M., Anthea Fursland, Gonzalez-Arce, V., Gouldthorp, B., Griffin, K., Griffiths, S., Hambleton, A., Hannigan, A., Hart, M., Hart, S., Hay, P., Hickie, I. B., King, R., Kohn, M., Krug, I., &amp; Linardon, J. (2023). Psychotherapies for eating disorders: Findings from a rapid review. <em>Journal of Eating Disorders</em>, <em>11</em>(1), 8&ndash;12.</p>
                        <p><a href="https://doi.org/10.1186/s40337-023-00886-w">https://doi.org/10.1186/s40337-023-00886-w</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )

}

export default Psycfpx3500assessment1
