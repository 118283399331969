import React from 'react';
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import {Helmet} from "react-helmet";


import './App.css';
import './Homepage.css'; 
import Home from './pages/Home';
import Create from './pages/Create';
import Login from './pages/Login';
import Post from './pages/Post';
import Register from './pages/Register';
import Samples from './pages/Samples';
import Footer from './components/Footer';
import Header from './components/Header';
import Order from './pages/Order';
import Mdadminpage from './pages/Mdadminpage';
import Scrolltotop from './components/Scrolltotop';
import Terms from './pages/Terms';
import Privacypolicy from './pages/Privacypolicy';
import Bsn from './pages/Nursing/Bsn';
import Msn from './pages/Nursing/Msn';
import Dnp from './pages/Nursing/Dnp';
import Bspsy from './pages/Psychology/Bspsy';
import Bspsypre from './pages/Psychology/Bspsypre';
import Msapplied from './pages/Psychology/Msapplied';
import Msclinical from './pages/Psychology/Msclinical';
import Mspsy from './pages/Psychology/Mspsy';
import Msschool from './pages/Psychology/Msschool';
import Edsschool from './pages/Psychology/Edsschool';
import Phdbehaviour from './pages/Psychology/Phdbehaviour';
import Phdpsyc from './pages/Psychology/Phdpsyc';
import Psydclinical from './pages/Psychology/Psydclinical';
import Psydschool from './pages/Psychology/Psydschool';
import Service from './pages/Services/Service';
import Thankyou from './pages/Thankyou';
import CapellaFlexpath from './pages/Services/CapellaFlexpath';
import MsnFlexpath from './pages/Services/MsnFlexpath';
import Dnpflexpath from './pages/Services/Dnpflexpath';
import CapellacapstoneBsn from './pages/Services/Capellacapstonebsn';
import Nursingdissertation from './pages/Services/Nursingdissertation';
import CapellaFlexPathAssisstance from './pages/Services/Capellaflexpathassisstance';
import DNPCapstoneProjectHelp from './pages/Services/Dnpcapstoneprojecthelp';
import DNPDissertationHelp from './pages/Services/DNPdissertationhelp';
import CapellaUniversityHelp from './pages/Services/Capellauniversityhelp';
import ReliableNursingHomeworkHelp from './pages/Services/Reliablenursinghomeworkhelp';
import BoostAcademicPerformance from './pages/Services/Boostacademicperformance';
import TakeMyOnlineClass from './pages/Services/Takemyonlineclass';
import AcademicAssignmentHelp from './pages/Services/Academicassignmenthelp';
import AssignmentWritingServices from './pages/Services/Assignmentwritingservices';
import NursingAssignment from './pages/Services/Nursingassignment';
import CheapAssignmentWriting from './pages/Services/Cheapassignmentwriting ';
import DoMyAssignment from './pages/Services/Domyassignment ';
import OnlineNursingClass from './pages/Services/Onlinenursingclass';
import Hiresomeonetdoyourclass from './pages/Services/Hiresomeonetdoyourclass';
import DoMyClass from './pages/Services/Domyclass';
import OnlineClassHelp from './pages/Services/Onlineclasshelp';
import TakeMyClassOnline from './pages/Services/Takemyclassonline';
import Paysomeonetotakemyclass from './pages/Services/Paysomeonetotakemyclass';
import Searchingforaperson from './pages/Services/Searchingforaperson';
import TakeMyOnlineCourse from './pages/Services/Takemyonlinecourse';
import Unlockingacademicsuccess from './pages/Services/Unlockingacademicsuccess';
import Paytotakemyonlineclass from './pages/Services/Paytotakemyonlineclass';
import Paysomeonetotakeonlineclasses from './pages/Services/Paysomeonetotakeonlineclasses';
import Domyclassforme from './pages/Services/Domyclassforme';
import Mbafpx5002mbaleadership from './pages/Services/Mbafpx5002mbaleadership';
import Mbafpx5006businessstrategy from './pages/Services/Mbafpx5006businessstrategy';
import Mbafpx5008appliedbusinessanalytics from './pages/Services/Mbafpx5008appliedbusinessanalytics';
import Mba5010accountingmethodsforleaders from './pages/Services/Mba5010accountingmethodsforleaders';
import Mbafpx5012marketingmanagement from './pages/Services/Mbafpx5012marketingmanagement';
import Mbafpx5014appliedmanagerialfinance from './pages/Services/Mbafpx5014appliedmanagerialfinance';
import Mbafpx5016operationsmanagementforleaders from './pages/Services/Mbafpx5016operationsmanagementforleaders';
import Mhafpx5001 from './pages/Services/Mhafpx5001';
import Mhafpx5006 from './pages/Services/Mhafpx5006';
import Mhafpx5010 from './pages/Services/Mhafpx5010';

import NURSFPX4060 from './pages/Samples/Nursfpx4060';
import Nursfpx from './pages/Samples/Nursfpx';
import Nursfpx4050assessment2 from './pages/Samples/Nursfpx4050assessment2';
import Nursfpx4060assessment4 from './pages/Samples/Nursfpx4060assessment4';
import Nursfpx4060assessment2 from './pages/Samples/Nursfpx4060assessment2';
import Nursfpx4020assessment4 from './pages/Samples/Nursfpx4020assessment4';
import Nursfpx4000assessment2 from './pages/Samples/Nursfpx4000assessment2';
import Nursfpx4030assessment1 from './pages/Samples/Nursfpx4030assessment1';
import Nursfpx4030assessment2 from './pages/Samples/Nursfpx4030assessment2';
import Nursfpx4000assessment1 from './pages/Samples/Nursfpx4000assessment1';
import Mhafpx5006A1 from './pages/Samples/Mhafpx5006A1';
import Mhafpx5006A2 from './pages/Samples/Mhafpx5006A2';
import Mhafpx5006A3 from './pages/Samples/Mhafpx5006A3';
import Mhafpx5006A4 from './pages/Samples/Mhafpx5006A4';
import Mhafpx5012 from './pages/Services/Mhafpx5012';
import Mhafpx5014 from './pages/Services/Mhafpx5014';
import Nursfpx8030a2 from './pages/Samples/Nursfpx8030a2';
import Nursfpx8014a2 from './pages/Samples/Nursfpx8014a2';
import Nursfpx6614a1 from './pages/Samples/Nursfpx6614a1';
import Nursfpx6021a1 from './pages/Samples/Nursfpx6021a1';
import Nursfpx8040a3 from './pages/Samples/Nursfpx8040a3';
import Nursfpx6016a1 from './pages/Samples/Nursfpx6016a1';
import Nursfpx5004a1 from './pages/Samples/Nursfpx5004a1';
import Nursfpx5004a2 from './pages/Samples/Nursfpx5004a2';
import Nursfpx5004a3 from './pages/Samples/Nursfpx5004a3';
import Nursfpx9901 from './pages/Samples/Nursfpx9901';
import Mbafpx5006A5 from './pages/Samples/Mbafpx5006A5';
import Nursfpx4010assessment1 from './pages/Samples/Nursfpx4010assessment1';
import Nursfpx6109assessment3 from './pages/Samples/Nursfpx6109assessment3';
import Nursfpx4020assessment3 from './pages/Samples/Nursfpx4020assessment3';
import Nursfpx4030assessment3 from './pages/Samples/Nursfpx4030assessment3';
import Nursfpx4040assessment2 from './pages/Samples/Nursfpx4040assessment2';
import Nursfpx4900assessment3 from './pages/Samples/Nursfpx4900assessment3';
import Nursfpx4060assessment3 from './pages/Samples/Nursfpx4060assessment3';
import Nursfpx4010assessment2 from './pages/Samples/Nursfpx4010assessment2';
import Nursfpx8010assessment1 from './pages/Samples/Nursfpx8010assessment1';
import Nursfpx4040assessment1 from './pages/Samples/Nursfpx4040assessment1';
import Nursfpx4030assessment4 from './pages/Samples/Nursfpx4030assessment4';
import Nursfpx4040assessment3 from './pages/Samples/Nursfpx4040assessment3';
import Nursfpx4060assessment1 from './pages/Samples/Nursfpx4060assessment1';
import Nursfpx4900assessment5 from './pages/Samples/Nursfpx4900assessment5';
import Nursfpx4900assessment6 from './pages/Samples/Nursfpx4900assessment6';
import Nursfpx6004assessment2 from './pages/Samples/Nursfpx6004assessment2';
import Nursfpx4050assessment3 from './pages/Samples/Nursfpx4050assessment3';
import Mbafpx5005assessment1 from './pages/Samples/Mbafpx5005assessment1';
import Mbafpx5006assessment1 from './pages/Samples/Mbafpx5006assessment1';
import Hypofpx5004assessment4 from './pages/Samples/Hypofpx5004assessment4';
import Bhafpx4104assessment1 from './pages/Samples/Bhafpx4104assessment1';
import Bhafpx4102assessment4 from './pages/Samples/Bhafpx4102assessment4';
import Nursfpx6105assessment1 from './pages/Samples/Nursfpx6105assessment1';
import Nursfpx6004assessment2policyproposal from './pages/Samples/Nursfpx6004assessment2policyproposal';
import Nursfpx6111assessment1 from './pages/Samples/Nursfpx6111assessment1';
import Nursfpx6612assessment1 from './pages/Samples/Nursfpx6612assessment1';
import Nursfpx6026assessment1 from './pages/Samples/Nursfpx6026assessment1';
import Nursfpx6004assessment1 from './pages/Samples/Nursfpx6004assessment1';
import Nursfpx6011assessment2 from './pages/Samples/Nursfpx6011assessment2';
import Nursfpx6612assessment2 from './pages/Samples/Nursfpx6612assessment2';
import Nursfpx6021assessment2 from './pages/Samples/Nursfpx6021assessment2';
import Nursfpx6616assessment1 from './pages/Samples/Nursfpx6616assessment1';
import Nursfpx6614assessment1 from './pages/Samples/Nursfpx6614assessment1';
import Nursfpx6030assessment2 from './pages/Samples/Nursfpx6030assessment2';
import Nursfpx6216assessment2 from './pages/Samples/Nursfpx6216assessment2';
import Nursfpx6004assessment3 from './pages/Samples/Nursfpx6004assessment3';
import Nursfpx6008assessment1 from './pages/Samples/Nursfpx6008assessment1';
import Bhafpx4010assessment3 from './pages/Samples/Bhafpx4010assessment3';
import Nursfpx6011assessment3 from './pages/Samples/Nursfpx6011assessment3';
import Nursfpx6030assessment3 from './pages/Samples/Nursfpx6030assessment3';
import Nursfpx6412assessment2 from './pages/Samples/Nursfpx6412assessment2';
import Nursfpx6025assessment2 from './pages/Samples/Nursfpx6025assessment2';
import Nursfpx6025assessment3 from './pages/Samples/Nursfpx6025assessment3';
import Nursfpx6212assessment2 from './pages/Samples/Nursfpx6212assessment2';
import Nursfpx6216assessment1 from './pages/Samples/Nursfpx6216assessment1';
import Nursfpx6216assessment3 from './pages/Samples/Nursfpx6216assessment3';
import Nursfpx6410assessment1 from './pages/Samples/Nursfpx6410assessment1';
import Nursfpx6416assessment2 from './pages/Samples/Nursfpx6416assessment2';
import Nursfpx6410assessment3 from './pages/Samples/Nursfpx6410assessment3';
import Nursfpx6218assessment3 from './pages/Samples/Nursfpx6218assessment3';
import Phifpx3200assessment2 from './pages/Samples/Phifpx3200assessment2';
import Mbafpx5014assessment1 from './pages/Samples/Mbafpx5014assessment1';
import Bhafpx4020assessment2 from './pages/Samples/Bhafpx4020assessment2';
import Mbafpx5010assessment1 from './pages/Samples/Mbafpx5010assessment1';
import Bhafpx4112assessment2 from './pages/Samples/Bhafpx4112assessment2';
import Humfpx1150assessment4 from './pages/Samples/Humfpx1150assessment4';
import Nursfpx5005assessment4 from './pages/Samples/Nursfpx5005assessment4';
import Nhsfpx4000assessment3 from './pages/Samples/Nhsfpx4000assessment3';
import Mbafpx5910assessment2 from './pages/Samples/Mbafpx5910assessment2';
import Busfpx3030assessment4 from './pages/Samples/Busfpx3030assessment4';
import Bhafpx4008assessment1 from './pages/Samples/Bhafpx4008assessment1';
import Busfpx3040assessment3 from './pages/Samples/Busfpx3040assessment3';
import Psycfpx3210assessment3 from './pages/Samples/Psycfpx3210assessment3';
import Psycfpx3500assessment1 from './pages/Samples/Psycfpx3500assessment1';
import Mbafpx5012assessment1 from './pages/Samples/Mbafpx5012assessment1';
import Bhafpx4102assessment3 from './pages/Samples/Bhafpx4102assessment3';
import Socfpx2000assessment3 from './pages/Samples/Socfpx2000assessment3';
import Nurs4900capstoneprojectfornursing from './pages/Services/Nurs4900capstoneprojectfornursing';
import Psycfpx3210assessment2 from './pages/Samples/Psycfpx3210assessment2';
import Nu631unit14dq from './pages/Samples/Nu631unit14dq';
import Nu631unit14dq2 from './pages/Samples/Nu631unit14dq2';

const Layout = ()=>{
    return(
        <>
        <Scrolltotop/>
        <Header />
        <Outlet />
        <Footer />
        </>
    )
}

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout/>,
      children: [
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/samples",
            element: <Samples />
        },
        {
            path: "/post/:id",
            element: <Post />
        },
        {
            path: "/create",
            element: <Create />
        },
        {
          path: "/order",
          element : <Order/>
        },
        {
          path: "/terms-and-conditions",
          element: <Terms/>
        },
        {
          path: "/privacy-policy",
          element: <Privacypolicy/>
        },
        {
          path: "/RN-to-BSN",
          element: <Bsn/>
        },
        {
          path: "/MSN",
          element: <Msn/>
        },
        {
          path: "/DNP",
          element: <Dnp/>
        },
        {
          path: "/BS-in-Psychology",
          element: <Bspsy/>
        },
        {
          path: "/BS-in-Psychology-Pre-Counseling",
          element: <Bspsypre/>
        },
        {
          path: "/MS-in-Applied-Behavior-Analysis",
          element: <Msapplied/>
        },
        {
          path: "/MS-in-Clinical-Psychology",
          element: <Msclinical/>
        },
        {
          path: "/MS-in-Psychology",
          element: <Mspsy/>
        },
        {
          path: "/MS-in-School-Psychology",
          element: <Msschool/>
        },
        {
          path: "/EdS-in-School-Psychology",
          element: <Edsschool/>
        },
        {
          path: "/PhD-in-Behavior-Analysis",
          element: <Phdbehaviour/>
        },
        {
          path: "/PhD-in-Psychology",
          element: <Phdpsyc/>
        },
        {
          path: "/PsyD-in-Clinical-Psychology",
          element: <Psydclinical/>
        },
        {
          path: "/PsyD-in-School-Psychology",
          element: <Psydschool/>
        },
        {
          path: "/thank-you",
          element: <Thankyou/>
        },
        {
          path: "/capella-flexpath-rn-bsn-classes-help",
          element: <Service/>
        },
        {
          path: "/take-comprehensive-help-in-capella-flex-path-assessments",
          element: <CapellaFlexpath/>
        },
        {
          path: "/take-the-guidance-in-capella-msn-flexpath-courses",
          element: <MsnFlexpath/>
        },{
          path: "/seek-capella-university-dnp-flexpath-help",
          element: <Dnpflexpath/>
        },
        {
          path: "/capella-capstone-project-bsn-help",
          element: <CapellacapstoneBsn/>
        },
        {
          path: "/nursing-dissertation-writing-services",
          element: < Nursingdissertation />
        },
        {
          path: "/need-assistance-with-capella-flexpath-nursing",
          element: < CapellaFlexPathAssisstance />
        },
        {
          path: "/get-dnp-capstone-project-help",
          element: < DNPCapstoneProjectHelp />
        },
        {
          path: "/comprehensive-dnp-dissertation-help",
          element: < DNPDissertationHelp />
        },
        {
          path: "/capella-university-help",
          element: < CapellaUniversityHelp />
        },
        {
          path: "/reliable-nursing-homework-help-with-our-online-coursework-help",
          element: < ReliableNursingHomeworkHelp />
        },
         {
          path: "/boost-academic-performance-with-our-online-coursework-help",
          element: < BoostAcademicPerformance />
        },
        {
          path: "/pay-someone-to-take-an-online-class",
          element: < TakeMyOnlineClass />
        },
        {
          path: "/academic-assignment-help",
          element: < AcademicAssignmentHelp />
        },
        {
          path: "/assignment-writing-services",
          element: < AssignmentWritingServices />
        },
        {
          path: "/need-help-with-nursing-assignment",
          element: < NursingAssignment />
        },
        {
          path: "/nurs-fpx-4060-assessment-3-disaster-recovery-plan",
          element: < NURSFPX4060 />
        },
        {
          path: "/cheap-assignment-writing-help-services",
          element: < CheapAssignmentWriting />
        },
        {
          path: "/do-my-assignment-online",
          element: < DoMyAssignment />
        },
        {
          path: "/take-my-online-nursing-class",
          element: < OnlineNursingClass />
        },
        {
          path: "/hire-someone-to-do-your-online-class",
          element: < Hiresomeonetdoyourclass />
        },
        {
          path: "/pay-someone-to-do-my-class",
          element: < DoMyClass />
        },
        {
          path: "/get-best-online-class-help",
          element: < OnlineClassHelp />
        },
        {
          path: "/take-my-class-online-for-me",
          element: < TakeMyClassOnline />
        },
        {
          path: "/pay-someone-to-take-my-class",
          element: < Paysomeonetotakemyclass />
        },
        {
          path: "/searching-for-a-person-who-can-take-my-online-class-for-me",
          element: < Searchingforaperson />
        },
        {
          path: "/take-my-online-course-for-me",
          element: < TakeMyOnlineCourse />
        },
        {
          path: "/unlocking-academic-success-the-role-of-online-class-takers",
          element: < Unlockingacademicsuccess />
        },
        {
          path: "/pay-to-take-my-online-class",
          element: < Paytotakemyonlineclass />
        },
        {
          path: "/pay-someone-to-take-online-classes",
          element: < Paysomeonetotakeonlineclasses />
        },
        {
          path: "/do-my-class-for-me",
          element: < Domyclassforme />
        },
        {
          path: "/mba-fpx-5002-mba-leadership",
          element: < Mbafpx5002mbaleadership />
        },
        {
          path: "/mba-fpx-5006-business-strategy",
          element: < Mbafpx5006businessstrategy />
        },
        {
          path: "/mba-fpx-5008-applied-business-analytics",
          element: < Mbafpx5008appliedbusinessanalytics />
        },
        {
          path: "/mba-fpx-5010-accounting-methods-for-leaders",
          element: < Mba5010accountingmethodsforleaders />
        },
        {
          path: "/mba-fpx-5012-marketing-management",
          element: < Mbafpx5012marketingmanagement />
        },
        {
          path: "/mba-fpx-5014-applied-managerial-finance",
          element: < Mbafpx5014appliedmanagerialfinance />
        },
        {
          path: "/mba-fpx-5016-operations-management-for-leaders",
          element: < Mbafpx5016operationsmanagementforleaders />
        },
        {
          path: "/mha-fpx-5001-foundations-of-master’s-studies-in-healthcare-administration",
          element: < Mhafpx5001 />
        },
        {
          path: "/mha-fpx-5006-healthcare-finance-and-reimbursement",
          element: < Mhafpx5006 />
        },
        {
          path: "/mha-fpx-5010-strategic-healthcare-planning",
          element: < Mhafpx5010 />
        },
        {
          path: "/mha-fpx-5012-organizational-leadership-and-governance",
          element: < Mhafpx5012 />
        },
        {
          path: "/mha-fpx-5014-healthcare-quality-risk-and-regulatory-compliance",
          element: < Mhafpx5014 />
        },
        {
          path: "/nurs-fpx",
          element: < Nursfpx />
        },
        {
          path: "/nurs-fpx-4060-assessment-4",
          element: < Nursfpx4060assessment4 />
        },
        {
          path: "/nurs-fpx-4050-assessment-2",
          element: < Nursfpx4050assessment2 />
        },
        {
          path: "/nurs-fpx-4060-assessment-2",
          element: < Nursfpx4060assessment2 />
        },
        {
          path: "/nurs-fpx-4020-assessment-4",
          element: < Nursfpx4020assessment4 />
        },
        {
          path: "/nurs-fpx-4000-assessment-2",
          element: < Nursfpx4000assessment2 />
        },
        {
          path: "/nurs-fpx-4030-assessment-1",
          element: < Nursfpx4030assessment1 />
        },
        {
          path: "/nurs-fpx-4030-assessment-2",
          element: < Nursfpx4030assessment2 />
        },
        {
          path: "/nurs-fpx-4000-assessment-1-pledge-of-academic-honesty",
          element: < Nursfpx4000assessment1 />
        },
        {
          path: "/mha-fpx-5006-assessment-1",
          element: < Mhafpx5006A1 />
        },
        {
          path: "/mha-fpx-5006-assessment-2",
          element: < Mhafpx5006A2 />
        },
        {
          path: "/mha-fpx-5006-assessment-3",
          element: < Mhafpx5006A3 />
        },
        {
          path: "/mha-fpx-5006-assessment-4",
          element: < Mhafpx5006A4 />
        },
        {
          path: "/nurs-fpx-8030-assessment-2-evidenced-based-literature",
          element: < Nursfpx8030a2 />
        },
        {
          path: "/nurs-fpx-8014-assessment-2-global-issue-problem-description",
          element: < Nursfpx8014a2 />
        },
        {
          path: "/nurs-fpx-6614-assessment-1",
          element: < Nursfpx6614a1 />
        },
        {
          path: "/nurs-fpx-6021-assessment-1",
          element: < Nursfpx6021a1 />
        },
        {
          path: "/nurs-fpx-8040-assessment-3",
          element: < Nursfpx8040a3 />
        },
        {
          path: "/nurs-fpx-6016-assessment-1",
          element: < Nursfpx6016a1 />
        },
        {
          path: "/nurs-fpx-5004-assessment-1-pledge-of-academic-honesty",
          element: < Nursfpx5004a1 />
        },
        {
          path: "/nurs-fpx-5004-assessment-2",
          element: < Nursfpx5004a2 />
        },
        {
          path: "/nurs-fpx-5004-assessment-3",
          element: < Nursfpx5004a3 />
        },
        {
          path: "/nurs-fpx-9901-assessment-3",
          element: < Nursfpx9901 />
        },
        {
          path: "/mba-fpx-5006-assessment-3",
          element: < Mbafpx5006A5 />
        },
        {
          path: "/nurs-fpx-4010-assessment-1-collaboration-and-leadership-reflection-video",
          element: < Nursfpx4010assessment1 />
        },
        {
          path: "/nurs-fpx-6109-assessment-3",
          element: < Nursfpx6109assessment3 />
        },
        {
          path: "/nurs-fpx-4020-assessment-3",
          element: < Nursfpx4020assessment3 />
        },
        {
          path: "/nurs-fpx-4030-assessment-3",
          element: < Nursfpx4030assessment3 />
        },
        {
          path: "/nurs-fpx-4040-assessment-2",
          element: < Nursfpx4040assessment2 />
        },
        {
          path: "/nurs-fpx-4900-assessment-3-assessing-the-problem",
          element: < Nursfpx4900assessment3 />
        },
        {
          path: "/nurs-fpx-4060-assessment-3",
          element: < Nursfpx4060assessment3 />
        },
        {
          path: "/nurs-fpx-4010-assessment-2-interview-and-interdisciplinary-issue-identification",
          element: < Nursfpx4010assessment2 />
        },
        {
          path: "/nurs-fpx-8010-assessment-1",
          element: < Nursfpx8010assessment1 />
        },
        {
          path: "/nurs-fpx-4040-assessment-1",
          element: < Nursfpx4040assessment1 />
        },
        {
          path: "/nurs-fpx-4030-assessment-4",
          element: < Nursfpx4030assessment4 />
        },
        {
          path: "/nurs-fpx-4040-assessment-3-evidence-based-proposal-and-annotated-bibliography-on-technology-in-nursing",
          element: < Nursfpx4040assessment3 />
        },
        {
          path: "/nurs-fpx-4060-assessment-1-health-promotion-plan",
          element: < Nursfpx4060assessment1 />
        },
        {
          path: "/nurs-fpx-4900-assessment-5-intervention-presentation-and-capstone-video-reflection",
          element: < Nursfpx4900assessment5 />
        },
        {
          path: "/nurs-fpx-4900-assessment-6-acknowledging-the-completion-and-documentation-of-nine-practicum-hours",
          element: < Nursfpx4900assessment6 />
        },
        {
          path: "/nurs-fpx-6004-assessment-2-policy-proposal",
          element: < Nursfpx6004assessment2 />
        },
        {
          path: "/nurs-fpx-6016-assessment-2-quality-improvement-initiative-evaluationl",
          element: < Nursfpx6004assessment2 />
        },
        {
          path: "/nurs-fpx-4050-assessment-3-care-coordination-presentation-to-colleagues",
          element: < Nursfpx4050assessment3 />
        },
        {
          path: "/mba-fpx-5005-assessment-1-protecting-human-research-participants",
          element: < Mbafpx5005assessment1 />
        },
        {
          path: "/mba-fpx-5006-assessment-1-health-care-finance-overview",
          element: < Mbafpx5006assessment1 />
        },
        {
          path: "/hypo-fpx-5004-assessment-4-self-assessment-of-leadership-collaboration-and-ethics",
          element: < Hypofpx5004assessment4 />
        },
        {
          path: "/bha-fpx-4140-assessment-1-strategic-planning-and-leadership",
          element: < Bhafpx4104assessment1 />
        },
        {
          path: "/bha-fpx-4102-assessment-4-conflict-resolution",
          element: < Bhafpx4102assessment4 />
        },
        {
          path: "/nurs-fpx-6105-assessment-1-learning-theories-and-diversity",
          element: < Nursfpx6105assessment1 />
        },
        {
          path: "/nurs-fpx-6004-assessment-2-policy-proposal",
          element: < Nursfpx6004assessment2policyproposal />
        },
        {
          path: "/nurs-fpx-6111-assessment-1-course-definition-and-alignment-table",
          element: < Nursfpx6111assessment1 />
        },
        {
          path: "/nurs-fpx-6612-assessment-1-triple-aim-outcome-measures",
          element: < Nursfpx6612assessment1 />
        },
        {
          path: "/nurs-fpx-6026-assessment-1-analysis-of-position-papers-for-vulnerable-populations",
          element: < Nursfpx6026assessment1 />
        },
        {
          path: "/nurs-fpx-6004-assessment-1-dashboard-metrics-evaluation",
          element: < Nursfpx6004assessment1 />
        },
        {
          path: "/nurs-fpx-6011-assessment-2-evidence-based-population-health-improvement-plan",
          element: < Nursfpx6011assessment2 />
        },
        {
          path: "/nurs-fpx-6612-assessment-2-quality-improvement-proposal",
          element: < Nursfpx6612assessment2 />
        },
        {
          path: "/nurs-fpx-6021-assessment-2-change-strategy-and-implementation",
          element: < Nursfpx6021assessment2 />
        },
        {
          path: "/nurs-fpx-6616-assessment-1-community-resources-and-best-practice",
          element: < Nursfpx6616assessment1 />
        },
        {
          path: "/nurs-fpx-6614-assessment-1-defining-a-gap-in-practice-executive-summary",
          element: < Nursfpx6614assessment1 />
        },
        {
          path: "/nurs-fpx-6030-assessment-1-problem-statement-PICOT",
          element: < Nursfpx6030assessment2 />
        },
        {
          path: "/nurs-fpx-6216-assessment-2-preparing-and-managing-an-operating-budget",
          element: < Nursfpx6216assessment2 />
        },
        {
          path: "/nurs-fpx-6004-assessment-3-training-session-for-policy-implementation",
          element: < Nursfpx6004assessment3/>
        },
        {
          path: "/nurs-fpx-6008-assessment-1-identifying-a-local-health-care-economic-issue",
          element: < Nursfpx6008assessment1 />
        },
        {
          path: "/bha-fpx-4010-assessment-3-quantitative-research-questions-and-methods",
          element: < Bhafpx4010assessment3/>
        },
        {
          path: "/nurs-fpx-6011-assessment-3-implementing-evidence-based-practice",
          element: < Nursfpx6011assessment3 />
        },
        {
          path: "/nurs-fpx-6030-assessment-3-intervention-plan-design",
          element: < Nursfpx6030assessment3 />
        },
        {
          path: "/nurs-fpx-6412-assessment-2-presentation-to-the-organization",
          element: < Nursfpx6412assessment2 />
        },
        {
          path: "/nurs-fpx-6025-assessment-2-practicum-and-experience-reflection",
          element: < Nursfpx6025assessment2 />
        },
        {
          path: "/nurs-fpx-6025-assessment-3-practicum-and-scholarly-article",
          element: < Nursfpx6025assessment3 />
        },
        {
          path: "/nurs-fpx-6212-assessment-2-executive-summary",
          element: < Nursfpx6212assessment2 />
        },
        {
          path: "/nurs-fpx-6216-assessment-1-mentor-interview",
          element: < Nursfpx6216assessment1 />
        },
        {
          path: "/nurs-fpx-6216-assessment-3-budget-negotiations-and-communications",
          element: < Nursfpx6216assessment3/>
        },
        {
          path: "/nurs-fpx-6410-assessment-1-presentation-to-informatics-staff",
          element: < Nursfpx6410assessment1 />
        },
        {
          path: "/nurs-fpx-6416-assessment-2-technology-needs-assessment-summary-and-implementation-plan",
          element: < Nursfpx6416assessment2 />
        },
        {
          path: "/nurs-fpx-6410-assessment-3-exploration-of-regulations-and-implications-for-practice",
          element: < Nursfpx6410assessment3 />
        },
        {
          path: "/nurs-fpx-6418-assessment-3-planning-for-community-and-organizational-change",
          element: < Nursfpx6218assessment3 />
        },
        {
          path: "/phi-fpx-3200-assessment-2-a-right-to-experimental-drugs",
          element: < Phifpx3200assessment2 />
        },
        {
          path: "/mba-fpx-5014-assessment-1-financial-condition-analysis",
          element: < Mbafpx5014assessment1/>
        },
        {
          path: "/bha-fpx-4020-assessment-2-health-care-professional-feedback",
          element: < Bhafpx4020assessment2/>
        },
        {
          path: "/bha-fpx-5010-assessment-1-accounting-tools-and-practices",
          element: < Mbafpx5010assessment1/>
        },
        {
          path: "/bha-fpx-4112-assessment-2-the-supply-and-demand-of-health-care",
          element: < Bhafpx4112assessment2/>
        },
        {
          path: "/hum-fpx-1150-assessment-4-creative-process-of-creating-and-interpreting-cultural-artifacts",
          element: < Humfpx1150assessment4/>
        },
        {
          path: "/nurs-fpx-5005-assessment-4-analysis-of-technology-in-nursing-practice",
          element: < Nursfpx5005assessment4/>
        },
        {
          path: "/nhs-fpx-4000-assessment-3-applying-ethical-principals",
          element: < Nhsfpx4000assessment3/>
        },
        {
          path: "/mba-fpx-5910-assessment-2-capstone-proposal",
          element: < Mbafpx5910assessment2/>
        },
        {
          path: "/bus-fpx-3030-assessment-4-promotion-analysis-and-sales-plan",
          element: < Busfpx3030assessment4/>
        },
        {
          path: "/bha-fpx-4008-assessment-1-developing-an-operating-budget",
          element: < Bhafpx4008assessment1/>
        },
        {
          path: "/bus-fpx-3040-assessment-3-retention-and-separation",
          element: < Busfpx3040assessment3/>
        },
        {
          path: "/psyc-fpx-3210-assessment-3-meet-the-washingtons-case-study",
          element: < Psycfpx3210assessment3/>
        },
        {
          path: "/psyc-fpx-3500-assessment-1-popular-versus-scholarly-sources-worksheet",
          element: < Psycfpx3500assessment1/>
        },
        {
          path: "/mba-fpx-5012-assessment-1-marketing-plan",
          element: < Mbafpx5012assessment1/>
        },
        {
          path: "/bha-fpx-4102-assessment-3-cultural-competence",
          element: < Bhafpx4102assessment3/>
        },
        {
          path: "/soc-fpx-2000-assessment-3-social-activism-and-data-analysis",
          element: < Socfpx2000assessment3/>
        },
        {
          path: "/nurs-4900-capstone-project-for-nursing",
          element: < Nurs4900capstoneprojectfornursing/>
        },
        {
          path: "/psyc-fpx-3210-assessment-3-pediatric-nurse",
          element: < Psycfpx3210assessment2/>
        },
        {
          path: "/nu-631-unit-14-dq-peer-response-for-discussion-post-1-dylan-parker",
          element: < Nu631unit14dq/>
        },
        {
          path: "/nu-631-unit-14-dq-peer-response-for-discussion-post-2-tracey-hight",
          element: < Nu631unit14dq2/>
        },

      ]

    },
    {
        path: "/register",
        element: <Register/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
    {
      path: "/md-admin-page",
      element: <Mdadminpage/>
    }
  ]);


const App = () => {
  return (
    <>
        <RouterProvider router={router}/>
        <Helmet>
          <title>Online Course Services</title>
          <meta name='description'
          content='Discover top-notch online course assistance worldwide, еspеcially in the USA.  
          Get еxpеrt help with online classes from our 24/7 service by US writers'/>
          <meta name='keywords' content='Online class help, help with online classes, take my online class, take my class online for me, online exam help'/>
        </Helmet>
    </>
  )
}

export default App
