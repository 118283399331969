import React from 'react'
import Carousel from 'react-grid-carousel';
import Form from './components/Form';
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet';

const Mbafpx5010assessment1 = () => {
    const newSamples = [
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nhs-fpx-6004-assessment-1-dashboard-metrics-evaluation/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-7-1.png",
            title: "NURS FPX 4060 Assessment 2 Community Resources",
            description: "Improvement Plan Tool KitThe improvement plan toolkit helps nurses implement and...",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-4060-assessment-2-community-resources/",
        },
        {
            image: "https://onlinecourseservices.us/free-samples/wp-content/uploads/2024/02/Topmycourse.com-2.png",
            title: "NURS FPX 4060 Assessment 1 Health Promotion Plan",
            description: "A health promotion plan is a preventative effort to enhance the quality of ....",
            samplelink: "https://onlinecourseservices.us/free-samples/nurs-fpx-6016-assessment-1/",
        }

    ]

    return (
        <>
            <Helmet>
                <title>MBA FPX 5010 Assessment 1 Accounting Tools and Practices</title>
                <meta name='description'
                    content='Prepare your MBA FPX 5010 Assessment 1 Accounting Tools and Practices with reliable support at online course services. Contact us to get professional support now!' />
                <meta name='keywords' content='MBA FPX 5010 Assessment 1 Accounting Tools and Practices' />
            </Helmet>

            <div className='hero-section'>
                <div className='main-box'>
                    <h1>MBA FPX 5010 Assessment 1 < br /><span>Accounting Tools and Practices</span></h1>
                </div>
            </div>

            <div className='sample-main-container'>
                <div className='main-container-sl'>
                    <img src="images/MBAFPX5010Assessment1.png" alt="" />
                    <div className='main-container-sl-content'>
                        <h2>Training&mdash;Accounting Tools and Practices</h2>
                        <p>The purpose of this training manual is to provide you with the information you need to comprehend the income statement and balance sheet of Urban Outfitters. The practical implementation of various accounting systems will be demonstrated, and their effects on these documents will be explained. Participants will get an understanding of the company's financial procedures and their practical applications through the training's examination of advertising, shop opening, and website development-related transactions. Any job participants pursue after graduation, notably, those in the banking or retail industries will benefit greatly from this information.</p>
                        <h2>Company Overview</h2>
                        <p>Since its founding in 1970 near Penn, Urban Outfitters, Inc. has expanded into a famous lifestyle retailer with over 200 stores in the US and other countries, including Canada (URBN, 202). Its meteoric rise from a mom-and-pop shop to a household name is an example of perseverance and achievement that we can all aspire to. Featuring brands such as Anthropologie and Free People, Urban Outfitters provides a diverse selection of products for men, women, and homes, with a focus on creativity and cultural understanding. Consistent product upgrades throughout apparel, accessories, home decor, intimates, athletic wear, and footwear demonstrate the relevance of Urban Outfitters' financial practices to our teaching. Urban Outfitters, headquartered in Philadelphia, Pennsylvania, has been recognized by Forbes for its dedication to inclusion and positive social effects (Forbes, 2024). The company actively engages with communities through events, social media, and chances for entrepreneurship.</p>
                        <h2>Financial Statements</h2>
                        <p>A company, individual, or entity's financial statements are formal records that detail all of their money dealings and provide information about their financial activities. For outside parties to evaluate a company's profitability, stability, and investment prospects, these documents are vital in demonstrating the company's financial health. Insights into a business's transactions can be found in great depth in financial statements. A company's operations and growth are propelled by these transactions, which encompass sales, purchases, and investments (SEC, 2023). By accurately documenting and presenting these events, financial statements provide a comprehensive evaluation of a company's financial health.</p>
                        <p>Table 1 shows the typical components of financial statements, which typically include an income statement, a balance sheet, and a statement of cash flows. Taken as a whole, these factors provide a complete picture of a business's health and performance. Due to their interdependence, these factors must be considered to provide a complete picture of a business's financial health.</p>
                        <table className='table'>
                            <tbody>
                                <tr>
                                    <td>
                                        <h2>Components</h2>
                                    </td>
                                    <td>
                                        <h2>Description</h2>
                                    </td>
                                    <td>
                                        <h2>Key Elements</h2>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Balance Sheet</p>
                                    </td>
                                    <td>
                                        <p>Provides an overview of the liabilities, assets, and shareholders' equity of a corporation as of a specific interval.</p>
                                    </td>
                                    <td>
                                        <p>Shareholders&rsquo; Equity Assets, and Liabilities</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Income Statement</p>
                                    </td>
                                    <td>
                                        <p>Provides a glimpse into the profitability of the business by showing its revenue, expenses, and costs for a certain period.</p>
                                    </td>
                                    <td>
                                        <p>Net Income, Costs, Revenues, and Expenses,</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <p>Cash Flow Statement</p>
                                    </td>
                                    <td>
                                        <p>The study summarizes the company's financial flexibility by outlining the flow of funds through investment, operational, and finance activities.</p>
                                    </td>
                                    <td>
                                        <p>Cash from Financing Activities, Cash from Operating Activities, and Cash from Investing Activities,</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <h2>&nbsp;</h2>
                        <h2>Balanced Sheets</h2>
                        <p>An essential financial document, a balance sheet shows the financial health of a business as of a specific date. They are useful indicators of a company's financial flexibility, stability, and investment attractiveness since they outline its liabilities, assets, and shareholders' equity.</p>
                        <p>Urban Outfitters, Inc.'s consolidated financial statements for the years ending January 31, 2023, and January 31, 2022, reveal several important details. From 2022 to 2023, total assets climbed from around $3.79 billion to over $3.68 billion (SEC, 2023). The primary drivers of this growth were an uptick in inventory, marketable securities, cash and equivalents, and other liquid assets. From almost $2.05 billion in 2022 to about $1.89 billion in 2023, there was a little decline in liabilities. This decrease indicates that the corporation has effectively managed its financial obligations and debts. From around $1.75 billion in 2022 to approximately $1.79 billion in 2023, there was a small rise in shareholders' equity (SEC, 2023). This increase in equity indicates that the company is doing well financially and is reinvesting its profits effectively.</p>
                        <p>With a solid asset-liability ratio and rising stockholder equity, Urban Outfitters, Inc. appears to be in a solid financial position on its balance sheets. This has the makings of a promising investment opportunity. Other financial accounts and performance measures would need to be examined for a more thorough review.</p>
                        <p><em>Figure 1: Urban Outfitters, Inc. (Consolidated Balance Sheet)</em></p>
                        <img src='images/MBAFPX5010px1.png' alt=''></img>
                        <h2>Income Statement</h2>
                        <p>Essential financial papers that reveal a company's revenue, expenses, and net profit or loss for a specific period are income statements, also known as profit and loss statements. You can learn more about a company's profitability, operating efficiency, and plans by consulting these records.</p>
                        <p>Several key pieces of information can be retrieved from the combined income statements of Urban Outfitters, Inc. for the fiscal year 2021, which ended on January 31, 2023, 2022. The company's revenue increased in 2023 as net sales were approximately $4.79 billion, up from $4.55 billion the year before (SEC, 2023). Going further, the cost of sales was approximately $3.36 billion in 2023, somewhat more than $3.05 billion the previous year. No matter what, the company's GPR jumped from $1.49 billion in 2022 to $1.43 billion in 2023, suggesting improved operational efficiency. In 2023, operational income dropped from a projected $404.63 million to around $226.62 million (SEC, 2023). However, in 2023, net income increased significantly from $310.62 million to $159.70 million. Diluted net income per common share fell from $3.17 in 2022 to $1.71 in 2023, while primary net income per common share fell from $1.70 to $1.70 in 2023 (SEC, 2023). One such cause could be a rise in the average number of common shares outstanding.</p>
                        <p>The financial statement for Urban Outfitters, Inc. shows that while net sales and gross profit were up, income from operations and net income per share were down. Further inquiry is necessary to determine the source of these alterations.</p>
                        <p><em>Figure 2: Urban Outfitters, Inc. Consolidated Income Statements</em></p>
                        <img src='images/mbafpxp2.png' alt=''></img>
                        <h2>Financial Statement Notes</h2>
                        <p>The key financial records, including the income statement, cash flow statement, balance sheet, and notes to financial statements for Urban Outfitters, are greatly improved by the inclusion of this essential supporting documentation. Because they detail the precise accounting principles and techniques used by the firm, especially regarding revenue recognition, these notes are vital. It is essential to appropriately measure the company's financial performance, and they provide clarity on when and how revenue is generated from different activities, like retail and online sales.</p>
                        <p>It is also possible to deduce from the notes if Urban Outfitters values their inventory using the FIFO or LIFO method. Decisions like this have a major impact on COGS and reported inventory values. Stakeholders can learn more about the company's long-term investment management process from the details provided, which include the depreciation techniques and the anticipated useful lifetimes of assets (Lizińska et al., 2024). Additionally, the comments provide insight into possible risks and management's expectations of future circumstances by being transparent about crucial estimates and judgments made by management, such as asset impairments or allowances for doubtful accounts. No one can make an informed decision based on these papers without the notes, which provide stakeholders with a deeper knowledge of the strategic decisions underlying the financial data.</p>
                        <h2>Advertising, Website Development, and Store Openings</h2>
                        <p>Spending on advertising, new store openings, and website development needs careful management if a company wants to stay afloat and flourish in the dynamic retail industry. One market leader that offers insight into how to record and manage such costs in financial accounts is Urban Outfitters. The study's overarching goal is to illuminate how these substantial outlays impacted the balance sheet and the accounting practices that recorded them. Observing Urban Outfitters' approach to financial management and strategic planning can teach district managers and other stakeholders a lot about these subjects, which are vital for directing a firm toward long-term profitability and competitive advantage (Stein, 2021). Here we will take a look at the several ways Urban Outfitters tracks these charges in their business procedures.</p>
                        <p><strong>Advertising</strong>: Advertising costs are deducted by Urban Outfitters as they are spent. Various channels, including social media, mobile applications, catalogs, and websites contribute to these costs through direct consumer advertising. Advertising expenditures are often detailed in the income statement (Fridson &amp; Alvarez, 2022). The lack of a mention of capitalized advertising costs in the financial records implies that Urban Outfitters does not charge advertising expenditures as they come in, although this could be wrong.</p>
                        <p><strong>Store Opening</strong>: When expenses are incurred, Urban Outfitters counts them as store opening costs. As a component of operational expenditures, these charges show up on the income statement. In most cases, the cost of a store's initial investment&mdash;like a building or major improvements&mdash;is best written off over the asset's useful life, which is either the length of the store's lease or a predetermined number of years that corresponds to the anticipated benefit period (usually 5-10 years). The predicted time frame for the store to achieve economic benefits would dictate this amortization term.</p>
                        <p><strong>Website Development</strong>: Urban Outfitters manages the substantial development expenses that come with building or improving a website by making the most of these expenses. Coding, developing, and setting up the website are all examples of capitalized costs. The goal is to create a platform that may generate future profits. These expenses are then spread out across its useful life, which represents the time it will take for the constructed website to generate money for the business (URBN, 2024). The rapid evolution of digital technology means that this time frame is usually somewhat brief, lasting about three to five years at the most. Recent website development expenses may not have fulfilled capitalization criteria or were not considerable, as indicated by Urban Outfitters' failure to capitalize on these costs in the last three years, according to the statement.</p>
                        <h2>Accounting Methods</h2>
                        <p>Knowing how a company keeps its books is crucial for deciphering its financial figures. When determining the value of an inventory, it is important to think about whether to use the FIFO or LIFO method. In its annual 10-K form, Urban Outfitters, Inc. states that it uses the FIFO method to value its inventory. This method usually allows a firm to claim reduced COGS and, therefore, higher net profits during inflationary times, assuming the oldest inventory goods are sold first. This is because the cost of goods sold generally records older, less expensive inventory first, before more recent, potentially more costly inventory (SEC, 2023). So, to show bigger total assets on the balance sheet, the remaining inventory is valued at the higher cost of more recent acquisitions.</p>
                        <p>Since a larger net income results in a higher taxable income, one key issue with the FIFO method is that it may cause income taxes to rise. Despite this, the financial experts at Urban Outfitters favor FIFO since it more accurately captures the costs of current inventory and typically produces higher profits (URBN, 2024). Using the LIFO method to prioritize selling newly purchased inventory would have reduced Urban Outfitters' net profits and increased COGS during price rises. This approach would lower the reported total assets by reducing the value of ending inventory on the balance sheet. While LIFO can streamline internal processes and record-keeping, it may not accurately reflect the physical movement of items and could result in reduced reported profits, allowing for a reduction in income taxes.</p>
                        <p>A thorough understanding of these procedures and their outcomes is essential for accurate financial analysis and sound strategic decision-making. This is especially the case when looking at how a business deals with its tax reporting and responsibilities.&nbsp;</p>
                        <p><strong>Comparing Two Accounting Methods</strong></p>
                        <p>If Urban Outfitters had valued its inventory using the more current LIFO method during price hikes instead of the more expensive FIFO strategy, the cost of goods sold (COGS) would have been higher. This would lead to a lower gross profit and potentially lower tax revenue when compared to FIFO (Baker et al., 2022). Using older, maybe cheaper pricing to value inventories might lead to a lower overall asset valuation that shows up on the balance sheet. For the company's reported financial health and tax liabilities, this type of move may have far-reaching effects. Similarly, instead of spending money on shop expansions or online development, Urban Outfitters might immediately increase its asset base and financial ratios by choosing to capitalize. However, because these costs would be amortized or depreciated during the asset's lifetime, they would appear in subsequent income statements (Baker et al., 2022). Since this approach has the potential to postpone the smoothing out of expense recognition, the impact on net income could not be immediately apparent.</p>
                        <h2><strong>Capitalizing </strong>versus Expensing</h2>
                        <p>Urban Outfitters, Inc. segments its expenditures strategically, labeling some as assets and others as expenses. The company has established standards to ensure that investments in areas such as advertising yield the highest possible return on investment. When these costs are recorded as assets on the balance sheet, the value of the company's assets increases. This allows for an increase in the company's reported profits. However, if similar costs were expensed instead of capitalized, they would be deducted from revenues in the income statement (Fridson &amp; Alvarez, 2022). Profits and net income for the company would fall because of this. Expenses like these are preferred over overcapitalization, even if the latter would lead to higher reported profits. This option is mostly driven by the immediate tax benefit associated with expensing. Instead of capitalizing, which spreads the cost of an expense out throughout its useful life, you should look into expensing if you want to get your money out of taxes faster. This method of allocating costs strategically is critical to Urban Outfitters, Inc.'s financial management and performance.</p>
                        <h2>Conclusion</h2>
                        <p>This training session gives a comprehensive review of Urban Outfitters, Inc.'s financial procedures and focuses on the significance of financial statements as well as the impact of various business segments and accounting systems on the company's financial performance. By becoming an expert in these areas and incorporating them into the company's strategy, decisions, and actions, the new district manager can help the business succeed in the ever-changing retail industry. If you want to be a good financial manager and help the firm reach its objectives, you need to learn these basics.</p>
                        <h2>References</h2>
                        <p>&zwnj; Baker, H. K., Filbeck, G., &amp; Barkley, T. (2022). <em>Working capital management: Concepts and strategies</em>. World Scientific. <a href="https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=W2S5EAAAQBAJ&amp;oi=fnd&amp;pg=PA65&amp;dq=current+LIFO+method+during+price+hikes+instead+of+the+more+expensive+FIFO+strategy,+the+cost+of+goods+sold+(COGS)+would+have+been+higher.&amp;ots=b3jUn1v7SR&amp;sig=R1FjvTZ_CsQddBAKcgs-5s7Fxy0&amp;redir_esc=y#v=onepage&amp;q&amp;f=false">https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=W2S5EAAAQBAJ&amp;oi=fnd&amp;pg=PA65&amp;dq=current+LIFO+method+during+price+hikes+instead+of+the+more+expensive+FIFO+strategy,+the+cost+of+goods+sold+(COGS)+would+have+been+higher.&amp;ots=b3jUn1v7SR&amp;sig=R1FjvTZ_CsQddBAKcgs-5s7Fxy0&amp;redir_esc=y#v=onepage&amp;q&amp;f=false</a></p>
                        <p>Forbes. (2024). <em>Urban Outfitters (URBN)</em>. Forbes. <a href="https://www.forbes.com/companies/urban-outfitters/">https://www.forbes.com/companies/urban-outfitters/</a></p>
                        <p>Fridson, M. S., &amp; Alvarez, F. (2022). <em>Financial statement analysis a practitioner&rsquo;s guide Workbook</em>. New York, NY Wiley. <a href="https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=2cl6EAAAQBAJ&amp;oi=fnd&amp;pg=PA25&amp;dq=Advertising+expenditures+are+often+detailed+in+the+income+statement&amp;ots=5YJAQu8EF4&amp;sig=hX2toXZ_t0rMmX-mSe1GTQbJoHk&amp;redir_esc=y#v=onepage&amp;q=Advertising%20expenditures%20are%20often%20detailed%20in%20the%20income%20statement&amp;f=false">https://books.google.com.pk/books?hl=en&amp;lr=&amp;id=2cl6EAAAQBAJ&amp;oi=fnd&amp;pg=PA25&amp;dq=Advertising+expenditures+are+often+detailed+in+the+income+statement&amp;ots=5YJAQu8EF4&amp;sig=hX2toXZ_t0rMmX-mSe1GTQbJoHk&amp;redir_esc=y#v=onepage&amp;q=Advertising%20expenditures%20are%20often%20detailed%20in%20the%20income%20statement&amp;f=false</a></p>
                        <p>Lizińska, J., Remlein, M., &amp; Czapiewski, L. (2024). <em>Earnings management and corporate finance</em>. Taylor &amp; Francis. <a href="https://www.taylorfrancis.com/chapters/edit/10.4324/9781032615448-2/long-term-earnings-management-based-fixed-assets-marzena-remlein-dawid-obrze%C5%BCgiewicz">https://www.taylorfrancis.com/chapters/edit/10.4324/9781032615448-2/long-term-earnings-management-based-fixed-assets-marzena-remlein-dawid-obrze%C5%BCgiewicz</a></p>
                        <p>SEC. (2023). <em>Urban Outfitters annual report 2023. </em>Stocklight. <a href="https://annualreport.stocklight.com/nasdaq/urbn/23793005.pdf">https://annualreport.stocklight.com/nasdaq/urbn/23793005.pdf</a></p>
                        <p>Stein, S. (2021). <em>Urban Outfitters is growing and its flagship&rsquo;s newest design shines</em>. Forbes. <a href="https://www.forbes.com/sites/sanfordstein/2021/04/26/urban-outfitters-is-growing-and-its-flagships-newest-design-shines/">https://www.forbes.com/sites/sanfordstein/2021/04/26/urban-outfitters-is-growing-and-its-flagships-newest-design-shines/</a></p>
                        <p>URBN. (2024). <em>Our history</em>. URBN. <a href="https://www.urbn.com/who-we-are/history">https://www.urbn.com/who-we-are/history</a></p>
                        <p>URBN. (2024).&nbsp;<em>URBN reports Q4 Results | Urban Outfitters, Inc. &ndash; IR Site</em>. Urban Outfitters, Inc. &ndash; IR Site. <a href="https://investor.urbn.com/news-releases/news-release-details/urbn-reports-q4-results-2">https://investor.urbn.com/news-releases/news-release-details/urbn-reports-q4-results-2</a></p>

                    </div>
                </div>
                <div className='main-container-sr'>
                    <div className='main-container-related-post'>
                        <h2>Related Post</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4000-assessment-2/'}>NHS FPX 4000 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4010-assessment-2/'}>NHS FPX 4010 Assessment 2</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4020-assessment-4-improvement-plan-tool-kit/'}>NHS FPX 4020 Assessment 4</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/nurs-fpx-4040-assessment-3/'}>NHS FPX 4040 Assessment 3</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nurs-fpx-4050-assessment-1'}>NHS FPX 4050 Assessment 1</Link></li>
                        </ul>
                    </div>
                    <div className='main-container-services'>
                        <h2>Services</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/capella-flexpath-rn-bsn-classes-help'}>Capella FlexPath RN-BSN Classes Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-comprehensive-help-in-capella-flex-path-assessments'}>Capella FlexPath Assessments</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/take-the-guidance-in-capella-msn-flexpath-courses'}>MSN FlexPath Courses</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/seek-capella-university-dnp-flexpath-help'}>DNP FlexPath Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/capella-capstone-project-bsn-help'}>Capella Capstone Project BSN Help</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/nursing-dissertation-writing-services'}>Nursing Dissertation Writing Services</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/need-assistance-with-capella-flexpath-nursing'}>Need Assistance With Capella Flexpath Nursing?</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/get-dnp-capstone-project-help'}>Get DNP Capstone project Help</Link></li>
                        </ul>
                    </div>
                    <div className='sample-more-details'>
                        <Carousel cols={1} rows={1} hideArrow={true} showDots={false} autoplay={2000} loop={true} dotColorActive={'#295fa9'} mobileBreakpoint={1200}>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Turnitin Originality</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Powerpoint Presentations</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Speech Notes</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Privacy Policy</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Video & Audio Assessment</p>
                                </span>
                            </Carousel.Item>
                            <Carousel.Item>
                                <span className='sample-carousel'>
                                    <p>Unlimited Revisions</p>
                                </span>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <Form />
                    <div className='main-container-category'>
                        <h2>Categories</h2>
                        <ul>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4000/'}>BSN NURS FPX 4000</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4010/'}>BSN NURS FPX 4010</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4020/'}>BSN NURS FPX 4020</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4030/'}>BSN NURS FPX 4030</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4040/'}>BSN NURS FPX 4040</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4050/'}>BSN NURS FPX 4050</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4060/'}>BSN NURS FPX 4060</Link></li>
                            <li><Link to={'https://onlinecourseservices.us/free-samples/category/nursing-class-sample/bsn/4900/'}>BSN NURS FPX 4900</Link></li>
                        </ul>
                    </div>

                </div>

            </div>
            <div className='freesamples services'>
                <h2>RELATED SAMPLES</h2>
                {/* <p>We provide excellent Capella University BSN FlexPath assessment solutions for students in their Capella BSN FlexPath Courses.</p> */}
                <div className='sampleboxes'>
                    {newSamples.map((sample) => {
                        return (

                            <div className='singlebox'>
                                <Link to={sample.samplelink}><img src={sample.image} alt='' /></Link>
                                <div className='text'>
                                    <h3><Link to={sample.samplelink} className='textdec'>{sample.title}</Link></h3>
                                    <p>{sample.description}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>


        </>
    )
}

export default Mbafpx5010assessment1
